import { useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material"
import { isBlank } from "../../utils/string"
import ProvinciaSelector from "../../components/form/ProvinciaSelector/ProvinciaSelector"
import css from './ModalCrearCentroEstudios.module.css'

const ModalCrearCentroEstudios = ({ open, onSubmit, onClose }) => {

  const [centroEstudios, setCentroEstudios] = useState({
    nombre: '',
    provincia: '',
  })

  const isSubmitEnabled = (
    !isBlank(centroEstudios.nombre) && 
    !isBlank(centroEstudios.provincia)
  )

  const handleSubmit = () => {
    onSubmit({ 
      nombre: centroEstudios.nombre,
      provincia: centroEstudios.provincia,
    })
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Añadir centro de estudios</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Puedes añadir un nuevo centro de estudios a la base de datos de <strong>mundo</strong>estudiante 
          rellenando el siguiente formulario.
        </DialogContentText>
        <TextField
          className={css.input}
          label="Nombre"
          value={centroEstudios.nombre}
          onChange={e=> setCentroEstudios({ ...centroEstudios, nombre: e.target.value })}
          variant='standard'
        />
        <ProvinciaSelector
          className={css.input}
          name='provincia'
          label='Provincia'
          value={centroEstudios.provincia}
          onChange={e=> setCentroEstudios({ ...centroEstudios, provincia: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>Cancelar</Button>
        <Button disabled={!isSubmitEnabled} color='primary' onClick={handleSubmit}>Crear</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalCrearCentroEstudios