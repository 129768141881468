import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import css from './EstadoCajaSelector.module.css'

const ESTADOS = [
  'SIN_ABRIR',
	'ABIERTA',
	'CERRADA',
]

const EstadoCajaSelector = ({ className, name, label, value, onChange })=> {

  return (
    <FormControl className={`${className} ${css.main}`} variant="standard">
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        labelId={name}
        id={name}
        value={value}
        onChange={onChange}
        label={label}
      >
        {ESTADOS.map(estado=> <MenuItem key={estado} value={estado}>{estado.replace("_", " ")}</MenuItem> )}
      </Select>
    </FormControl>
  )

}

export default EstadoCajaSelector

