import { useState } from 'react'
import { default as MaterialTabs } from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import css from './Tabs.module.css'

const TabArea = ({ className, tabs, selectedTab, onChange }) => (
  <div className={`${className} ${css.tabs}`}>
    <MaterialTabs variant="fullWidth" scrollButtons={false} indicatorColor="secondary" textColor="inherit" value={tabs.findIndex(tab => tab.name === selectedTab)} onChange={(_e, newTab) => onChange(tabs[newTab].name)}>
      {tabs.map(tab => (
        <Tab key={tab.name} label={tab.label || tab.name} />
      ))}
    </MaterialTabs>
  </div>
)

const TabContent = ({ active = false, children }) => {
  if (!active) return null
  return children
}

const Tabs = ({ className, tabs, activeTab, onChange }) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0].name)

  const relevantTab = activeTab || selectedTab

  const handleChange = tab => {
    if (onChange) onChange(tab)
    else setSelectedTab(tab)
  }

  return (
    <>
      <TabArea className={className} tabs={tabs} selectedTab={relevantTab} onChange={handleChange} />
      {tabs.map(tab => (
        <TabContent key={tab.name} active={tab.name === relevantTab}>
          {tab.content}
        </TabContent>
      ))}
    </>
  )
}

export default Tabs
