import { FormControl, InputLabel, ListSubheader, MenuItem, Select } from "@mui/material"
import css from './SubnivelAsignaturaSelector.module.css'

const IDIOMAS = [
  'A1', 'A2', 'B1', 'B2', 'C1', 'C2',
]

const INTERNACIONAL = 'INTERNACIONAL'

const SubnivelAsignaturaSelector = ({ className, name, label, value, onChange })=> (
  <FormControl className={`${className} ${css.main}`} variant="standard">
    <InputLabel id={name}>{label}</InputLabel>
    <Select
      labelId={name}
      id={name}
      value={value}
      onChange={onChange}
      label={label}
    >
      <MenuItem value=''>
        <em className={css.placeholder}>Sin subnivel</em>
      </MenuItem>
      <ListSubheader>Idioma</ListSubheader>
      {IDIOMAS.map(nivel=> <MenuItem key={nivel} value={nivel}>{nivel}</MenuItem> )}
      <ListSubheader>Internacional</ListSubheader>
      <MenuItem value={INTERNACIONAL}>{INTERNACIONAL}</MenuItem>
    </Select>
  </FormControl>
)

export default SubnivelAsignaturaSelector

