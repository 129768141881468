import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import css from './ModalDesasignarIngreso.module.css'

const ModalDesasignarIngreso = ({ open, onSubmit, onClose }) => (
  <Dialog onClose={onClose} open={open} fullWidth maxWidth='sm'>
    <DialogTitle>Confirmar desasignación</DialogTitle>
    <DialogContent className={css.main}>
      <DialogContentText className={css.intro}>
        Estás a punto de desasignar un ingreso de <strong>mundo</strong>estudiante. Esta operacion es <strong>irreversible</strong>,
        por favor, asegúrate de que has elegido el ingreso correcto antes de proceder a desasignarlo
        <br />
        Desasignarlo lo desvinculará de la contratación a la que estuviera asociado, alterando la contabilidad del alumno.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button color='error' onClick={onClose}>Cancelar</Button>
      <Button color='primary' onClick={onSubmit}>Desasignar el ingreso</Button>
    </DialogActions>
  </Dialog>
)

export default ModalDesasignarIngreso