import { useQuery } from '@tanstack/react-query'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { getUsuarioList } from '../../../utils/api/usuarios'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import { dateToString } from '../../../utils/date'
import css from './UsuarioSelector.module.css'

const UsuarioSelector = ({
  name,
  label,
  className = '',
  value,
  showEmpty = true,
  onChange
}) => {

  const notification = useNotification()

  const { data: usuarioList=[] } = useQuery({
    queryKey: ['usuario', 'list'], 
    queryFn: ()=> getUsuarioList()
      .then(datos=> datos.map(usuario=> ({
        ...usuario,
        ultimaConexion: dateToString(usuario.ultimaConexion) || '--'
      })))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los usuarios', content: err })
        return []
      })
  })

  return (
    <FormControl
      variant='standard' 
      className={`${className} ${css.main}`}
      fullWidth
    >
      <InputLabel>{label}</InputLabel>
      <Select
        name={name}
        labelId={name}
        value={value}
        onChange={onChange}
        label={label}
      >
        {showEmpty && (
          <MenuItem className={css.empty} value=''>
            Sin usuario
          </MenuItem>
        )}
        {usuarioList.map(option => (
          <MenuItem key={option.id} value={option.id}>
            {option.nombre}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default UsuarioSelector
