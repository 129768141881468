import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material"
import FechaSelector from "../../components/form/FechaSelector/FechaSelector"
import AcademiaSelector from "../../components/form/AcademiaSelector/AcademiaSelector"
import CompatibilidadSelector from "../../components/form/CompatibilidadSelector/CompatibilidadSelector"
import css from "./ModalCrearCodigoPromotional.module.css"
import TipoCodigoPromocionalSelector from "../../components/form/TipoCodigoPromocionalSelector/TipoCodigoPromocionalSelector"
import { isAnyBlank } from "../../utils/string"
import { useState } from "react"
import NumberInput from "../../components/form/NumberInput/NumberInput"
import { getCompatibilidadTipo, getDescuentoLabel } from "../../utils/codigos"
import { dateToString } from "../../utils/date"

const ModalCrearCodigoPromocional = ({
  open,
  onSubmit,
  onClose,
}) => {

  const [codigo, setCodigo] = useState({
    nombre: '',
    descripcion: '',
    centros: [],
    compatibilidad: [],
    tipo: '',
    valorDescuento: '',
    fechaInicio: null,
    fechaFin: null,
  })

  const isSubmitDisabled = isAnyBlank(
    codigo.nombre,
    codigo.descripcion,
    codigo.centros,
    codigo.compatibilidad,
    codigo.tipo,
    codigo.valorDescuento,
    codigo.fechaInicio,
    codigo.fechaFin
  )

  const isCompatible = getCompatibilidadTipo(codigo.tipo, codigo.compatibilidad)

  const showDescuento = ['DESCUENTO_PRECIO', 'DESCUENTO_PORCENTAJE', 'HORAS_GRATIS'].includes(codigo.tipo)

  const handleSubmit = () => {
    const codigoPromocional = {
      nombre: codigo.nombre,
      descripcion: codigo.descripcion,
      tipo: codigo.tipo,
      fecha_inicio: dateToString(codigo.fechaInicio),
      fecha_fin: dateToString(codigo.fechaFin),
      "centros[]": codigo.centros,
      "compatibilidad[]": codigo.compatibilidad,
    }
    if (codigo.tipo === 'DESCUENTO_PRECIO' ) codigoPromocional.descuento_fijo = codigo.valorDescuento
    if (codigo.tipo === 'DESCUENTO_PORCENTAJE' ) codigoPromocional.descuento_porcentaje = codigo.valorDescuento
    if (codigo.tipo === 'HORAS_GRATIS' ) codigoPromocional.horas_gratis = codigo.valorDescuento
    onSubmit(codigoPromocional)
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="md">
      <DialogTitle>Crear código promocional</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Puedes generar un código promocional completando el siguiente
          formulario. Recuerda que todos los campos son obligatorios
        </DialogContentText>
        {!isCompatible && (
          <Alert className={css.warningBanner} severity="warning">
            El tipo de código promocional no es válido.<br />
            {codigo.tipo === 'DESCUENTO_PORCENTAJE' && <>Los códigos de descuento por porcentaje no son compatibles con pagos.</>}
            {codigo.tipo === 'HORAS_GRATIS' && <>Los códigos de horas gratis solo son compatibles con tarifas.</>}
          </Alert>
        )}
        <TextField
          className={css.input}
          label="Nombre"
          variant="standard"
          value={codigo.nombre}
          onChange={e=> setCodigo({ ...codigo, nombre: e.target.value })}
        />
        <TextField
          className={css.input}
          label="Descripción"
          name="descripcion"
          multiline
          maxRows={4}
          variant="standard"
          value={codigo.descripcion}
          onChange={e=> setCodigo({ ...codigo, descripcion: e.target.value })}
        />
        <AcademiaSelector
          className={css.input}
          label="Academias"
          multiple
          name="centros"
          value={codigo.centros}
          onChange={e=> setCodigo({ ...codigo, centros: e.target.value })}
        />
        <TipoCodigoPromocionalSelector
          className={css.input}
          label="Tipo de descuento"
          name="tipo"
          value={codigo.tipo}
          onChange={e=> setCodigo({ ...codigo, tipo: e.target.value })}
        />
        <CompatibilidadSelector
          className={css.input}
          label="Compatibilidad"
          name="compatibilidad"
          value={codigo.compatibilidad}
          onChange={e=> setCodigo({ ...codigo, compatibilidad: e.target.value })}
          multiple
        />
        {showDescuento && (
          <NumberInput
            className={css.input}
            label={getDescuentoLabel(codigo.tipo)}
            value={codigo.valorDescuento}
            onChange={valorDescuento=> setCodigo({ ...codigo, valorDescuento })}
          />
        )}
        <FechaSelector
          className={css.input}
          label="Fecha inicio"
          value={codigo.fechaInicio}
          onChange={fechaInicio=> setCodigo({ ...codigo, fechaInicio })}
        />
        <FechaSelector
          className={css.input}
          label="Fecha fin"
          value={codigo.fechaFin}
          onChange={fechaFin=> setCodigo({ ...codigo, fechaFin })}
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose}>
          Cancelar
        </Button>
        <Button
          color="primary"
          disabled={isSubmitDisabled || !isCompatible}
          onClick={handleSubmit}
        >
          Crear
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalCrearCodigoPromocional
