import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { MONTHS } from "../../../utils/date"
import css from './MesSelector.module.css'

const MesSelector = ({ className='', name, label, value, onChange })=> {

  return (
    <FormControl className={`${className} ${css.main}`} variant="standard">
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        labelId={name}
        id={name}
        value={value}
        onChange={onChange}
        label={label}
      >
        {MONTHS.map((mes, index)=> <MenuItem key={mes} value={index+1}>{mes}</MenuItem> )}
      </Select>
    </FormControl>
  )

}

export default MesSelector