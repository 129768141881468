import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import css from './FormaPagoSelector.module.css'

const FORMAS_PAGO = [
  'EFECTIVO',
	'TARJETA',
	'TRANSFERENCIA',
]

const FormaPagoSelector = ({ className, name, label, value, onChange })=> {

  return (
    <FormControl className={`${className} ${css.main}`} variant="standard">
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        labelId={name}
        id={name}
        value={value}
        onChange={onChange}
        label={label}
      >
        {FORMAS_PAGO.map(pago=> <MenuItem key={pago} value={pago}>{pago}</MenuItem> )}
      </Select>
    </FormControl>
  )

}

export default FormaPagoSelector

