import { IconButton } from '@mui/material'
import { useState } from 'react'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import PageLoading from '../../../components/PageLoading/PageLoading'
import InformationTable from '../../../components/Table/InformationTable'
import TableHeader from '../../../components/TableHeader/TableHeader'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import { getHelpdesk, resolveHelpdesk } from '../../../utils/api/helpdesk'
import { downloadCSVFile, getCSVContent } from '../../../utils/csv'
import { dateToString } from '../../../utils/date'
import { dateSort, textFilter } from '../../../utils/table'
import css from './Helpdesk.module.css'

const ContenidoHelpdesk = ({ title, content })=> (
  <>
    <strong className={css.title}>{title}</strong>
    <p className={css.content}>{content}</p>
  </>
)

const Helpdesk = ()=> {

  const notification = useNotification()
  const queryClient = useQueryClient()

  const [search, setSearch] = useState('')

  const { isLoading, data: helpdeskList=[] } = useQuery({
    queryKey: ['helpdesk', 'list'], 
    queryFn: ()=> getHelpdesk()
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los helpdesk', content: err })
        return []
      })
  })

  const { isFetching: isUpdatingHelpdesk, mutate: solveHelpdesk} = useMutation({
    mutationFn: resolveHelpdesk,
    onSuccess: ()=> {
      notification.success({ title: 'Helpdesk resuelto', content: 'Se ha marcado el helpdesk como resuelto' })
      queryClient.invalidateQueries(['helpdesk', 'list'])
    },
    onError: err=> notification.error({ title: 'Error al resolver el helpdesk', content: err }),
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Centro', 'Categoría', 'Fecha', 'Título', 'Descripcion'],
      data: helpdeskList,
      transform: h=> ([
        h.categoria,
        h.centro,
        dateToString(h.fecha),
        h.titulo,
        h.descripcion,
      ])
    })
    downloadCSVFile(content, 'helpdesk')
  }

  const handleResolveHelpdesk = (id)=> {
    if (isUpdatingHelpdesk) return
    solveHelpdesk({ id })
  }

  const filterSearch = helpdesk=> {
    return textFilter({ 
      object: helpdesk, 
      fields: ['titulo', 'descripcion', 'centro'],
      search: search
    })
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={(
          <IconButton size='small' color='primary' onClick={handleExport}>
            <i className='material-icons'>download</i>
          </IconButton>
        )}
        title='Helpdesk pendientes'
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        details={[
          { title: 'Categoría', key: 'categoria', align: 'left' },
          { title: 'Centro', key: 'centro', align: 'left' },
          { title: 'Fecha', key: 'fecha', sortFunction: dateSort, align: 'left' },
          { title: 'Contenido', key: 'contenido', sortDisabled: true, align: 'left' },
          { title: 'Opciones', key: 'opciones', sortDisabled: true, align: 'center' }
        ]}
        data={helpdeskList
          .filter(filterSearch)
          .map(helpdesk=> ({
            categoria: helpdesk.categoria,
            centro: helpdesk.centro,
            fecha: dateToString(helpdesk.fecha),
            contenido: <ContenidoHelpdesk title={helpdesk.titulo} content={helpdesk.descripcion} />,
            opciones: (
              <IconButton 
                size='small' 
                color='primary' 
                onClick={()=> handleResolveHelpdesk(helpdesk.id)}
              >
                <i className='material-icons'>check</i>
              </IconButton>
            )
          }))
        }
      />
    </PageLoading>
  )

}

export default Helpdesk