import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { IconButton } from '@mui/material'
import EjercicioSelector from '../../../../components/form/EjercicioSelector/EjercicioSelector'
import MesSelector from '../../../../components/form/MesSelector/MesSelector'
import PageLoading from '../../../../components/PageLoading/PageLoading'
import TableHeader from '../../../../components/TableHeader/TableHeader'
import InformationTable from '../../../../components/Table/InformationTable'
import CodigoPromocionalSelector from '../../../../components/form/CodigoPromocionalSelector/CodigoPromocionalSelector'
import { getCodigosPromocionalesUsage } from '../../../../utils/api/codigosPromocionales'
import { downloadCSVFile, getCSVContent } from '../../../../utils/csv'
import { useNotification } from '../../../../context/NotificationManager/NotificationManager'
import css from './Codigos.module.css'

const Codigos = () => {
  const notification = useNotification()

  const [mes, setMes] = useState(new Date().getMonth() + 1)
  const [ejercicio, setEjercicio] = useState(new Date().getFullYear())
  const [codigo, setCodigo] = useState('')

  const { isLoading: isLoadingCodigos, data: codigoList = [] } = useQuery({
    queryKey: ['codigo-promocional', 'list', 'usage', mes, ejercicio, codigo],
    enabled: !!codigo,
    queryFn: () =>
      getCodigosPromocionalesUsage({ mes: `${mes}/${ejercicio}`, codigo })
        .then(datos => Object.keys(datos).map(academia => ({ academia, ingresos: datos[academia] })))
        .catch(err => {
          notification.error({ title: 'Error al recuperar los codigos', content: err })
          return []
        })
  })

  const handleExport = () => {
    const content = getCSVContent({
      columns: ['Academia', 'Pagos', 'Tarifas', 'Pack Horas'],
      data: codigoList,
      transform: c => [
        c.academia, 
        c.ingresos.pagos || '0', 
        c.ingresos.pagos_tarifa || '0', 
        c.ingresos.pagos_pack_horas || '0'
      ]
    })
    downloadCSVFile(content, `codigos-promocionales-${codigo}-${mes}-${ejercicio}`)
  }

  return (
    <PageLoading isLoading={isLoadingCodigos}>
      <TableHeader
        actions={
          <div className={css.actions}>
            <MesSelector name="mes" label="Mes" value={mes} onChange={e => setMes(e.target.value)} />
            <EjercicioSelector className={css.year} name="ejercicio" label="Ejercicio" value={ejercicio} onChange={e => setEjercicio(e.target.value)} />
            <CodigoPromocionalSelector className={css.codigo} mes={`${mes}/${ejercicio}`} name="codigo" label="Código promocional" value={codigo} onChange={e => setCodigo(e.target.value)} />
            <IconButton className={css.download} size="small" color="primary" onClick={handleExport}>
              <i className="material-icons">download</i>
            </IconButton>
          </div>
        }
        title="Códigos promocionales"
        showSearch={false}
      />
      <InformationTable
        details={[
          { title: 'Academia', key: 'academia', align: 'left' },
          { title: 'Pagos', key: 'pago', align: 'center' },
          { title: 'Tarifas', key: 'tarifa', align: 'center' },
          { title: 'Pack horas', key: 'packHoras', align: 'center' }
        ]}
        data={codigoList.map(codigo => ({
          academia: codigo.academia,
          pago: codigo.ingresos.pagos,
          tarifa: codigo.ingresos.pagos_tarifa,
          packHoras: codigo.ingresos.pagos_pack_horas
        }))}
      />
    </PageLoading>
  )
}

export default Codigos
