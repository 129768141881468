import { useEffect, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material"
import { isBlank } from "../../utils/string"
import { isHoraValida } from "../../utils/date"
import EstadoCajaSelector from "../../components/form/EstadoCajaSelector/EstadoCajaSelector"
import CurrencyInput from "../../components/form/CurrencyInput/CurrencyInput"
import css from './ModalEditarCaja.module.css'

const ModalEditarCaja = ({ caja, open, onSubmit, onClose }) => {

  const [cajaUpdate, setCajaUpdate] = useState({
    id: '',
    balanceInicial: 0,
    balanceFinal: 0,
    horaApertura: '',
    horaCierre: '',
    estado: '',
  })
  
  useEffect(()=> {
    if (open) return
    setCajaUpdate({
      id: '',
      balanceInicial: 0,
      balanceFinal: 0,
      horaApertura: '',
      horaCierre: '',
      estado: '',
    })
  } , [open])

  useEffect(()=> {
    if (!caja || !caja.id) return
    if (cajaUpdate.id !== caja.id) {
      setCajaUpdate({
        id: caja.id,
        balanceInicial: caja.balanceInicial,
        balanceFinal: caja.balanceFinalReal,
        horaApertura: caja.horaApertura,
        horaCierre: caja.horaCierre,
        estado: caja.estado,
      })
    }
  }, [cajaUpdate.id, caja])

  const isSubmitEnabled = (
    !isBlank(cajaUpdate.id) &&
    !isBlank(cajaUpdate.horaApertura) &&
    isHoraValida(cajaUpdate.horaApertura) &&
    !isBlank(cajaUpdate.horaCierre) &&
    isHoraValida(cajaUpdate.horaCierre) &&
    !isBlank(cajaUpdate.estado)
  )

  const handleSubmit = () => {
    onSubmit({ 
      id: cajaUpdate.id,
      balance_inicial: cajaUpdate.balanceInicial,
      balance_final_real: cajaUpdate.balanceFinal,
      hora_apertura: cajaUpdate.horaApertura,
      hora_cierre: cajaUpdate.horaCierre,
      estado: cajaUpdate.estado,
    })
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Editar caja</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Puedes modificar los datos de la caja con el siguiente formulario.
        </DialogContentText>
        <TextField
          className={css.input}
          label="Hora apertura"
          value={cajaUpdate.horaApertura}
          onChange={e=> setCajaUpdate({ ...cajaUpdate, horaApertura: e.target.value })}
          variant='standard'
          error={!isHoraValida(cajaUpdate.horaApertura)}
          helperText={!isHoraValida(cajaUpdate.horaApertura) ? 'La hora debe estar en formato HH:MM' : ''}
        />
        <TextField
          className={css.input}
          label="Hora Cierre"
          value={cajaUpdate.horaCierre}
          onChange={e=> setCajaUpdate({ ...cajaUpdate, horaCierre: e.target.value })}
          variant='standard'
          error={!isHoraValida(cajaUpdate.horaCierre)}
          helperText={!isHoraValida(cajaUpdate.horaCierre) ? 'La hora debe estar en formato HH:MM' : ''}
        />
        <EstadoCajaSelector
          className={css.input}
          name='estado'
          label='Estado'
          value={cajaUpdate.estado}
          onChange={e=> setCajaUpdate({ ...cajaUpdate, estado: e.target.value })}
        />
        <CurrencyInput
          className={css.input}
          name='balanceInicial'
          label='Balance inicial'
          value={cajaUpdate.balanceInicial}
          onChange={value=> setCajaUpdate({ ...cajaUpdate, balanceInicial: value })}
        />
        <CurrencyInput
          className={css.input}
          name='balanceFinal'
          label='Balance final'
          value={cajaUpdate.balanceFinal}
          onChange={value=> setCajaUpdate({ ...cajaUpdate, balanceFinal: value })}
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>Cancelar</Button>
        <Button disabled={!isSubmitEnabled} color='primary' onClick={handleSubmit}>Actualizar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEditarCaja