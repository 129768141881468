import css from './PageLoading.module.css'

const PageLoading = ({ isLoading, children })=> {
  if (isLoading) return (
    <div className={css.loading}>
      <div className={css.spinner} />
    </div>
  )
  else return children
}

export default PageLoading