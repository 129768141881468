import { IconButton } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import PageLoading from '../../../components/PageLoading/PageLoading'
import InformationTable from '../../../components/Table/InformationTable'
import TableHeader from '../../../components/TableHeader/TableHeader'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import { getCentros } from '../../../utils/api/centros'
import { downloadCSVFile, getCSVContent } from '../../../utils/csv'
import { dateToString } from '../../../utils/date'
import css from './Academias.module.css'

const sortCentros = (centro1, centro2)=> {
  const { fechaApertura: fa1, fechaCierre: fc1 } = centro1
  const { fechaApertura: fa2, fechaCierre: fc2 } = centro2
  // Si uno de los centros está cerrado - va al final
  if (fc1 && !fc2) return 1
  if (fc2 && !fc1) return -1
  // Si no, situamos según fecha apertura
  return fa1 - fa2
}

const Activo = ()=> (
  <span className={css.activo}>Activo</span>
)

const Direccion = ({ direccion })=> (
  <>
    <p className={css.direccion}>{direccion.direccion}</p>
    <p className={css.region}>{direccion.codigoPostal} - {direccion.municipio.municipio}</p>
  </>
)

const Academias = ()=> {

  const notification = useNotification()

  const { isLoading, data: academiaList=[] } = useQuery({
    queryKey: ['centro', 'list', 'details'], 
    queryFn: ()=> getCentros()
    .then(datos=> datos)
    .catch(err=> {
      notification.error({ title: 'Error al recuperar los datos de academias', content: err })
      return []
    })
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Nombre', 'Fecha Apertura', 'Fecha Cierre', 'Telefono', 'Dirección', 'Código Postal', 'Municipio', 'Sociedad', 'Capacidad'],
      data: academiaList,
      transform: a=> ([
        a.nombre,
        dateToString(a.fechaApertura),
        a.fechaCierre ? dateToString(a.fechaCierre) : 'Activo',
        a.telefono,
        a.direccion.direccion,
        a.direccion.codigoPostal,
        a.direccion.municipio.municipio,
        a.sociedad,
        a.capacidad,
      ])
    })
    downloadCSVFile(content, 'academias')
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={(
          <IconButton size='small' color='primary' onClick={handleExport}>
            <i className='material-icons'>download</i>
          </IconButton>
        )}
        title='Datos de los centros'
        showSearch={false}
      />
      <InformationTable
        details={[
          { title: 'Nombre', key: 'nombre', sortDisabled: true, align: 'left' },
          { title: 'Apertura / Cierre', key: 'fechas', sortDisabled: true, align: 'left' },
          { title: 'Teléfono', key: 'telefono', sortDisabled: true, align: 'left' },
          { title: 'Dirección', key: 'direccion', sortDisabled: true, align: 'left' },
          { title: 'Sociedad', key: 'sociedad', sortDisabled: true, align: 'left' },
          { title: 'Capacidad', key: 'capacidad', sortDisabled: true, align: 'center' }
        ]}
        data={academiaList
          .sort(sortCentros)
          .map(centro=> ({
            nombre: centro.nombre,
            fechas: (
              <>
                {dateToString(centro.fechaApertura)} - {centro.fechaCierre ? dateToString(centro.fechaCierre) : <Activo />}
              </>
            ),
            telefono: centro.telefono,
            direccion: <Direccion direccion={centro.direccion} />,
            sociedad: centro.sociedad,
            capacidad: centro.capacidad
          }))
        }
      />
    </PageLoading>
  )

}

export default Academias