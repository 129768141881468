import React, { useEffect, useState } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material"
import FechaSelector from "../../components/form/FechaSelector/FechaSelector"
import AcademiaSelector from "../../components/form/AcademiaSelector/AcademiaSelector"
import CompatibilidadSelector from "../../components/form/CompatibilidadSelector/CompatibilidadSelector"
import { isAnyBlank } from "../../utils/string"
import NumberInput from "../../components/form/NumberInput/NumberInput"
import TipoCodigoPromocionalSelector from "../../components/form/TipoCodigoPromocionalSelector/TipoCodigoPromocionalSelector"
import { getDescuentoLabel } from "../../utils/codigos"
import { dateToDateTime } from "../../utils/date"
import css from "./ModalEditarCodigoPromocional.module.css"

const ModalEditarCodigoPromocional = ({
  open,
  codigo,
  onSubmit,
  onClose,
}) => {

  const [codigoUpdate, setCodigoUpdate] = useState({
    id: '',
    nombre: '',
    descripcion: '',
    centros: [],
    compatibilidad: [],
    tipo: '',
    valorDescuento: '',
    fechaInicio: null,
    fechaFin: null,
  })
  
  useEffect(()=> {
    if (open) return
    setCodigoUpdate({
      id: '',
      nombre: '',
      descripcion: '',
      centros: [],
      compatibilidad: [],
      tipo: '',
      valorDescuento: '',
      fechaInicio: null,
      fechaFin: null,
    })
  } , [open])

  useEffect(()=> {
    if (!codigo || !codigo.id) return
    if (codigoUpdate.id !== codigo.id) {

      let valor = 0;
      if (codigo.tipo === 'DESCUENTO_PRECIO') valor = codigo.descuentoFijo
      if (codigo.tipo === 'DESCUENTO_PORCENTAJE') valor = codigo.descuentoPorcentaje
      if (codigo.tipo === 'HORAS_GRATIS') valor = codigo.horasGratis

      setCodigoUpdate({
        id: codigo.id,
        nombre: codigo.nombre,
        descripcion: codigo.descripcion,
        centros: codigo.centros,
        compatibilidad: codigo.compatibilidad,
        tipo: codigo.tipo,
        valorDescuento: valor,
        fechaInicio: dateToDateTime(codigo.fechaInicio),
        fechaFin: dateToDateTime(codigo.fechaFin),
      })
    }
  }, [codigoUpdate.id, codigo])

  const showDescuento = ['DESCUENTO_PRECIO', 'DESCUENTO_PORCENTAJE', 'HORAS_GRATIS'].includes(codigoUpdate.tipo)

  const isSubmitDisabled = isAnyBlank(
    codigoUpdate.nombre,
    codigoUpdate.descripcion,
    codigoUpdate.centros,
    codigoUpdate.compatibilidad,
    codigoUpdate.tipo,
    codigoUpdate.valorDescuento,
    codigoUpdate.fechaInicio,
    codigoUpdate.fechaFin
  )

  const handleSubmit = () => {
    const codigoPromocional = {
      id: codigoUpdate.id,
      descripcion: codigoUpdate.descripcion,
      tipo: codigoUpdate.tipo,
      fecha_inicio: codigoUpdate.fechaInicio,
      fecha_fin: codigoUpdate.fechaFin,
      "centros[]": codigoUpdate.centros,
      "compatibilidad[]": codigoUpdate.compatibilidad,
    }
    if (codigoUpdate.tipo === 'DESCUENTO_PRECIO' ) codigoPromocional.descuento_fijo = codigoUpdate.valorDescuento
    if (codigoUpdate.tipo === 'DESCUENTO_PORCENTAJE' ) codigoPromocional.descuento_porcentaje = codigoUpdate.valorDescuento
    if (codigoUpdate.tipo === 'HORAS_GRATIS' ) codigoPromocional.horas_gratis = codigoUpdate.valorDescuento
    onSubmit(codigoPromocional)
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="md">
      <DialogTitle>Editar código promocional</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Puedes modificar un código promocional actualizando el siguiente
          formulario.
        </DialogContentText>
        <TextField
          className={css.input}
          label="Nombre"
          variant="standard"
          disabled
          value={codigoUpdate.nombre}
          onChange={e=> setCodigoUpdate({ ...codigoUpdate, nombre: e.target.value })}
        />
        <TextField
          className={css.input}
          label="Descripción"
          name="descripcion"
          multiline
          maxRows={4}
          variant="standard"
          value={codigoUpdate.descripcion}
          onChange={e=> setCodigoUpdate({ ...codigoUpdate, descripcion: e.target.value })}
        />
        <AcademiaSelector
          className={css.input}
          label="Academias"
          multiple
          name="centros"
          value={codigoUpdate.centros}
          onChange={e=> setCodigoUpdate({ ...codigoUpdate, centros: e.target.value })}
        />
        <TipoCodigoPromocionalSelector
          className={css.input}
          label="Tipo de descuento"
          name="tipo"
          value={codigoUpdate.tipo}
          onChange={e=> setCodigoUpdate({ ...codigoUpdate, tipo: e.target.value })}
        />
        <CompatibilidadSelector
          className={css.input}
          label="Compatibilidad"
          name="compatibilidad"
          value={codigoUpdate.compatibilidad}
          onChange={e=> setCodigoUpdate({ ...codigoUpdate, compatibilidad: e.target.value })}
          multiple
        />
        {showDescuento && (
          <NumberInput
            className={css.input}
            label={getDescuentoLabel(codigoUpdate.tipo)}
            value={codigoUpdate.valorDescuento}
            onChange={valorDescuento=> setCodigoUpdate({ ...codigoUpdate, valorDescuento })}
          />
        )}
        <FechaSelector
          className={css.input}
          label="Fecha inicio"
          value={codigoUpdate.fechaInicio}
          onChange={fechaInicio=> setCodigoUpdate({ ...codigoUpdate, fechaInicio })}
        />
        <FechaSelector
          className={css.input}
          label="Fecha fin"
          value={codigoUpdate.fechaFin}
          onChange={fechaFin=> setCodigoUpdate({ ...codigoUpdate, fechaFin })}
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose}>
          Cancelar
        </Button>
        <Button disabled={isSubmitDisabled} color='primary' onClick={handleSubmit}>Actualizar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEditarCodigoPromocional
