import { Fab, IconButton } from '@mui/material'
import { useState } from 'react'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import ProvinciaSelector from '../../../components/form/ProvinciaSelector/ProvinciaSelector'
import PageLoading from '../../../components/PageLoading/PageLoading'
import InformationTable from '../../../components/Table/InformationTable'
import TableHeader from '../../../components/TableHeader/TableHeader'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import ModalCrearCentroEstudios from '../../../modals/ModalCrearCentroEstudios/ModalCrearCentroEstudios'
import ModalEditarCentroEstudios from '../../../modals/ModalEditarCentroEstudios/ModalEditarCentroEstudios'
import { createCentroEstudios, getCentrosEstudio, getCentrosEstudioProvincia, updateCentroEstudios } from '../../../utils/api/centroEstudios'
import { downloadCSVFile, getCSVContent } from '../../../utils/csv'
import { textFilter } from '../../../utils/table'
import css from './CentrosEstudio.module.css'

const CentrosEstudio = ()=> {

  const notification = useNotification()
  const queryClient = useQueryClient()

  const [provincia, setProvincia] = useState('')
  const [search, setSearch] = useState('')

  const [isCreateModalOpen, setCreateModalOpen] = useState(false)

  const [isEditModalOpen, setEditModalOpen] = useState(false)
  const [centroEstudiosEdit, setCentroEstudiosEdit] = useState(null)

  const { isLoading, data: centroEstudioList=[] } = useQuery({
    queryKey: ['centro_estudios', provincia], 
    queryFn: ()=> {
      const query = provincia ? getCentrosEstudioProvincia({ provincia }) : getCentrosEstudio()
      return query
        .then(datos=> datos)
        .catch(err=> {
          notification.error({ title: 'Error al recuperar los centros de estudio', content: err })
          return []
        })
    }
  })

  const { isFetching: isCreatingCentro, mutate: crearCentroEstudios } = useMutation({
    mutationFn: createCentroEstudios,
    onSuccess: ()=> {
      notification.success({ title: 'Centro creado', content: 'El centro de estudios ha sido creado correctamente' })
      queryClient.invalidateQueries(['centro_estudios'])
      setCreateModalOpen(false)
    },
    onError: err=> notification.error({ title: 'Error al crear el centro', content: err }),
  })

  const { isFetching: isUpdatingCentro, mutate: actualizarCentroEstudios } = useMutation({
    mutationFn: updateCentroEstudios,
    onSuccess: ()=> {
      setEditModalOpen(false)
      notification.success({ title: 'Centro actualizado', content: 'El centro de estudios ha sido actualizado correctamente' })
      queryClient.invalidateQueries(['centro_estudios'])
    },
    onError: err=> notification.error({ title: 'Error al actualizar el centro', content: err }),
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Nombre', 'Provincia'],
      data: centroEstudioList,
      transform: c=> ([
        c.nombre,
        c.provincia,
      ])
    })
    downloadCSVFile(content, 'centros-estudio')
  }

  const handleCrearCentro = (centroEstudios)=> {
    if (isCreatingCentro) return
    crearCentroEstudios(centroEstudios)
  }

  const handleEditarCentro = (centroEstudios)=> {
    if (isUpdatingCentro) return
    actualizarCentroEstudios(centroEstudios)
  }

  const handleOpenCentroEstudiosEdit = (centroEstudios)=> {
    setCentroEstudiosEdit(centroEstudios)
    setEditModalOpen(true)
  }

  const filterSearch = centro=> {
    return textFilter({ 
      object: centro, 
      fields: ['nombre', 'provincia'],
      search: search
    })
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <IconButton className={css.download} size='small' color='primary' onClick={handleExport}>
              <i className='material-icons'>download</i>
            </IconButton>
            <ProvinciaSelector
              name='provincia'
              label='Provincia'
              showAll
              value={provincia}
              onChange={e=> setProvincia(e.target.value)}
            />
          </div>
        )}
        title='Centros de estudio'
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        size='small'
        details={[
          { title: 'Nombre', key: 'nombre', align: 'left' },
          { title: 'Provincia', key: 'provincia', align: 'left' },
          { title: 'Opciones', key: 'opciones', sortDisabled: true, align: 'center' },
        ]}
        data={centroEstudioList
          .filter(filterSearch)
          .map(centro=> ({
            nombre: centro.nombre,
            provincia: centro.provincia,
            opciones: (
              <IconButton 
                size='small' 
                color='secondary'
                onClick={()=> handleOpenCentroEstudiosEdit(centro)}
              >
                <i className='material-icons'>edit</i>
              </IconButton>
            )
          }))
        }
      />
      <Fab
        className={css.nuevo}
        disabled={createCentroEstudios.status === 'loading'}
        onClick={()=> setCreateModalOpen(true)}
        size="medium"
        color="primary" 
        aria-label="add"
      >
        <i className='material-icons'>add</i>
      </Fab>
      <ModalCrearCentroEstudios
        open={isCreateModalOpen}
        onSubmit={handleCrearCentro}
        onClose={()=> setCreateModalOpen(false)}
      />
      <ModalEditarCentroEstudios 
        centroEstudios={centroEstudiosEdit}
        open={isEditModalOpen}
        onSubmit={handleEditarCentro}
        onClose={()=> setEditModalOpen(false)}
      />
    </PageLoading>
  )

}

export default CentrosEstudio