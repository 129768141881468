import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import css from './ModalBorrarFactura.module.css'

const ModalBorrarFactura = ({ open, onSubmit, onClose }) => (
  <Dialog onClose={onClose} open={open} fullWidth maxWidth='sm'>
    <DialogTitle>Confirmar borrado de factura</DialogTitle>
    <DialogContent className={css.main}>
      <DialogContentText className={css.intro}>
        Estás a punto de borrar una factura de <strong>mundo</strong>estudiante. Esta operacion es <strong>irreversible</strong>,
        por favor, asegúrate de que has elegido la factura correcta antes de proceder al borrado.
        <br />
        La factura aparecerá como anulada en el sistema, pero no se borrara de la base de datos. Aparecerán los datos 
        como <code>Factura anulada</code>. Esto se debe a que la numeración de las facturas es consecutiva, y no queremos 
        dejar huecos.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button color='error' onClick={onClose}>Cancelar</Button>
      <Button color='primary' onClick={onSubmit}>Borrar la factura</Button>
    </DialogActions>
  </Dialog>
)

export default ModalBorrarFactura