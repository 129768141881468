import { useEffect, useState } from "react"
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel } from "@mui/material"
import css from './ModalEditarHorario.module.css'

const HORAS = [
  '06:00-07:00', '07:00-08:00', '08:00-09:00', '09:00-10:00', '10:00-11:00', 
  '11:00-12:00', '12:00-13:00', '13:00-14:00', '14:00-15:00', '15:00-16:00', 
  '16:00-17:00', '17:00-18:00', '18:00-19:00', '19:00-20:00', '20:00-21:00', 
  '21:00-22:00', '22:00-23:00'
]

const MEDIAS = [
  '06:30-07:30', '07:30-08:30', '08:30-09:30', '09:30-10:30', '10:30-11:30',
  '11:30-12:30', '12:30-13:30', '13:30-14:30', '14:30-15:30', '15:30-16:30',
  '16:30-17:30', '17:30-18:30', '18:30-19:30', '19:30-20:30', '20:30-21:30',
  '21:30-22:30', '22:30-23:30'
]

const Hora = ({ hora, checked, onChange }) => (
  <FormControlLabel
    control={<Checkbox checked={checked} onChange={onChange} name={hora} />}
    label={hora}
  />
)

const ModalEditarHorario = ({ open, horario=[], onSubmit, onClose }) => {

  const [horas, setHoras] = useState(horario)

  useEffect(()=> {
    if (open) setHoras(horario || [])
  }, [horario, open])

  const isSubmitEnabled = horas.length > 0

  const handleSubmit = () => {
    onSubmit({ horas })
  }

  const handleHoraClick = (event, hora)=> {
    if (event.target.checked) setHoras([...horas, hora])
    else setHoras(horas.filter(h=> h !== hora))
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='sm'>
      <DialogTitle>Actualizar horario</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Puedes actualizar el horario modificando las horas de apertura. Sin embargo esta operación fallará 
          si ya hay programadas clases o pruebas ese día.
        </DialogContentText>
        <div className={css.rows}>
          <div className={css.row}>
            {HORAS.map(hora=> (
              <Hora 
                key={hora}
                hora={hora}
                checked={horas.includes(hora)}
                onChange={(e)=> handleHoraClick(e, hora)}
              />
            ))}
          </div>
          <div className={css.row}>
            {MEDIAS.map(hora=> (
              <Hora
                key={hora}
                hora={hora}
                checked={horas.includes(hora)}
                onChange={(e)=> handleHoraClick(e, hora)}
              />
            ))}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>Cancelar</Button>
        <Button disabled={!isSubmitEnabled} color='primary' onClick={handleSubmit}>Actualizar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEditarHorario