import { useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material"
import CurrencyInput from "../../components/form/CurrencyInput/CurrencyInput"
import { isBlank } from "../../utils/string"
import css from './ModalModificarPago.module.css'

const ModalModificarPago = ({ open, onSubmit, onClose }) => {

  const [importe, setImporte] = useState(0)
  const [justificacion, setJustificacion] = useState('')

  const isSubmitEnabled = (
    importe !== 0 && !isBlank(justificacion)
  )

  const handleSubmit = () => {
    onSubmit({ importe, justificacion })
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='sm'>
      <DialogTitle>Modificar pago</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Puedes hacer una modificación del pago. El importe que añadas se sumará al importe actual del pago.
        </DialogContentText>
        <CurrencyInput
          className={css.input}
          name='importe'
          label="Modificación"
          value={importe}
          allowNegatives
          onChange={value=> setImporte(value)}
        />
        <TextField
          className={css.input}
          label="Justificacion"
          value={justificacion}
          onChange={e=> setJustificacion(e.target.value)}
          variant='standard'
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>Cancelar</Button>
        <Button disabled={!isSubmitEnabled} color='primary' onClick={handleSubmit}>Modificar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalModificarPago