import { useEffect, useState } from 'react'
import { Button, Typography } from '@mui/material'
import { getAuth, removeAuth } from '../../utils/auth'
import core from './core-white.png'
import timestamp from '../../utils/timestamp'
import { useNavigate } from 'react-router'
import { useNotification } from '../../context/NotificationManager/NotificationManager'
import { useIsFetching } from '@tanstack/react-query'
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator'
import css from './Header.module.css'

const Header = ({ onHelpClick })=> {

  const navigate = useNavigate()
  const notification = useNotification()
  const fetchingQueries = useIsFetching()
  
  const [time, setTime] = useState('')


  useEffect(()=> {
    const interval = setInterval(()=> setTime(timestamp()), 1000)
    return ()=> clearInterval(interval)
  }, [])

  const handleRefresh = ()=> window.location.reload(true)

  const handleLogout = ()=> {
    removeAuth()
    notification.success({ title: 'Sesión cerrada', content: 'Se ha cerrado la sesión correctamente'})
    navigate('/login')
  }

  const handleMenuCollapse = ()=> {
    const collapseValue = document.body.getAttribute("data-collapse")
    if (collapseValue) document.body.removeAttribute("data-collapse")
    else document.body.setAttribute("data-collapse", 'collapsed')
  }

  return (
    <header className={css.header}>
      <div className={css.logo}>
        <img className={css.image} src={core} alt='Core' />
      </div>
      <div className={css.actions}>
        {fetchingQueries > 0 && (
          <LoadingIndicator number={fetchingQueries} />
        )}
        <Typography className={css.clock}>{time}</Typography>
        <img className={css.profile} src={getAuth().foto} alt='' />
        <Button className={css.button} variant='contained' onClick={onHelpClick}>
          <i className='material-icons'>help</i>
        </Button>
        <Button className={css.button} variant='contained' onClick={handleMenuCollapse}>
          <i className='material-icons'>menu</i>
        </Button>
        <Button className={css.button} variant='contained' onClick={handleRefresh}>
          <i className='material-icons'>cached</i>
        </Button>
        <Button className={css.button} variant='contained' onClick={handleLogout}>
          <i className='material-icons'>power_settings_new</i>
        </Button>
      </div>
    </header>
  )
}

export default Header