export const format = (number)=> {
  if (number === null || number === undefined || isNaN(number)) number = 0
  if (!isFinite(number)) return '∞'
  return number.toFixed(2)
}

export const formatForExport = number=> {
  if (number === null || number === undefined || isNaN(number)) number = 0
  if (!isFinite(number)) return '∞'
  return number.toFixed(2).replace('.', ',')
}