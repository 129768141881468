import { IconButton, Tooltip } from '@mui/material'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { format, formatForExport } from '../../../../utils/math'
import { downloadCSVFile, getCSVContent } from '../../../../utils/csv'
import PageLoading from '../../../../components/PageLoading/PageLoading'
import InformationTable from '../../../../components/Table/InformationTable'
import TableHeader from '../../../../components/TableHeader/TableHeader'
import { useNotification } from '../../../../context/NotificationManager/NotificationManager'
import MesRecienteSelector from '../../../../components/form/MesRecienteSelector/MesRecienteSelector'
import AcademiaSelector from '../../../../components/form/AcademiaSelector/AcademiaSelector'
import { getAuth } from '../../../../utils/auth'
import { dateToString, monthToString } from '../../../../utils/date'
import { dateSort, textFilter } from '../../../../utils/table'
import { getModificados } from '../../../../utils/api/pagos'
import css from './Modificaciones.module.css'

const ListaModificaciones = ({ modificaciones })=> (
  <>
    {modificaciones.map((modificacion, i)=> (
      <p key={i}>{modificacion}</p>
    ))}
  </>
)

const Modificaciones = ()=> {

  const notification = useNotification()

  const [mes, setMes] = useState(monthToString(new Date()))
  const [centro, setCentro] = useState(getAuth().centro)
  const [search, setSearch] = useState('')

  const { isLoading: isLoadingPagos, data: pagoList=[] } = useQuery({
    queryKey: ['pago', 'modified', 'list', mes, centro], 
    queryFn: ()=> getModificados({ mes, centro })
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los pagos modificados', content: err })
        return []
      })
  })


  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Alumno', 'Concepto', 'Centro', 'Tipo', 'Importe', 'Modificación'],
      data: pagoList,
      transform: pago=> ([
        pago.alumno,
        pago.concepto,
        pago.centro,
        pago.tipo,
        formatForExport(pago.importe),
        formatForExport(pago.modificacion)
      ])
    })
    downloadCSVFile(content, 'pagos-modificados-mes')
  }

  const filterSearch = pago=> {
    return textFilter({ 
      object: pago, 
      fields: ['alumno', 'concepto'],
      search: search
    })
  }

  return (
    <PageLoading isLoading={isLoadingPagos}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <AcademiaSelector
              name='academia'
              label='Academia'
              value={centro}
              onChange={e=> setCentro(e.target.value)}
            />
            <MesRecienteSelector
              className={css.mes}
              name='mes'
              label='Mes'
              value={mes}
              onChange={e=> setMes(e.target.value)}
            />
            <IconButton className={css.download} size='small' color='primary' onClick={handleExport}>
              <i className='material-icons'>download</i>
            </IconButton>
          </div>
        )}
        title='Pagos modificados'
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        details={[
          { title: 'Alumno', key: 'alumno', align: 'left' },
          { title: 'Concepto', key: 'concepto', align: 'left' },
          { title: 'Fecha', key: 'fecha', sortFunction: dateSort, align: 'left' },
          { title: 'Importe', key: 'importe', sortDisabled: true, align: 'center' },
          { title: 'Modificación', key: 'modificacion', sortDisabled: true, align: 'center' },
        ]}
        data={pagoList
          .filter(filterSearch)
          .map(pago=> ({
            alumno: pago.alumno,
            concepto: pago.concepto,
            fecha: dateToString(pago.fecha),
            importe: `${format(pago.importe)} €`,
            modificacion: (
              <Tooltip arrow title={<ListaModificaciones modificaciones={pago.modificaciones} />}>
                <span className={css.modificacion} data-status={pago.modificacion > 0 ? 'positive' : 'negative'}>
                  {pago.modificacion > 0 ? '+' : ''}{format(pago.modificacion)} €
                </span>
              </Tooltip>
            ),
          }))
        }
      />
    </PageLoading>
  )

}

export default Modificaciones