import React, { useState } from "react"
import { Alert, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel } from "@mui/material"
import { getDateWeekday } from "../../utils/date"
import css from './ModalBorrarClasesProfesor.module.css'

const Hora = ({ clase, checked, onChange }) => (
  <FormControlLabel
    control={(
      <Checkbox 
        color="error"
        checked={checked} 
        onChange={onChange} 
        name={clase.id} 
      />
    )}
    label={clase.hora}
  />
)

const ModalBorrarClasesProfesor = ({ open, clasesDisponibles, onSubmit, onClose }) => {

  const [clases, setClases] = useState([])

  const isSubmitEnabled = clases.length > 0

  const clasesCentro = clasesDisponibles
    .reduce((acc, clase)=> {
      const centro = clase.centro
      const centroClases = acc.find(c=> c.centro === centro)
      if (centroClases) centroClases.clases.push(clase)
      else acc.push({ centro, clases: [clase] })
      return acc
    }, [])
    .map(({ centro, clases })=> {
      const clasesDia = clases.reduce((acc, clase)=> {
        const fecha = clase.fecha
        const fechaClases = acc.find(c=> c.fecha === fecha)
        if (fechaClases) fechaClases.clases.push(clase)
        else acc.push({ fecha, clases: [clase] })
        return acc
      }, [])
      return { centro, clases: clasesDia }
    })

  const handleSubmit = () => {
    onSubmit({ clases })
  }

  const handleClaseClick = (event, clase)=> {
    if (event.target.checked) setClases([...clases, clase])
    else setClases(clases.filter(c=> c !== clase))
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Borrar horarios</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Selecciona las horas de clase que quieres borrar.
        </DialogContentText>
        <Alert className={css.warningBanner} severity="warning">
          Se desasignarán a todos los alumnos y pruebas asignados a este profesor en estas clases.
        </Alert>
        {clasesCentro.map(({ centro, clases: clasesCentro })=> (
          <React.Fragment key={centro}>
            <p className={css.centro}>
              <i className='material-icons'>school</i>
              <span className={css.label}>{centro}</span>
            </p>
            <div className={css.content}>
              {clasesCentro.map(clasesFecha=> (
                <React.Fragment key={clasesFecha.fecha}>
                  <p className={css.fecha}>
                    {getDateWeekday(clasesFecha.fecha)}{' '}
                    {clasesFecha.fecha}:
                  </p>
                  <div className={css.rows}>
                    {clasesFecha.clases.map(clase=> (
                      <div className={css.row} key={clase.id}>
                        <Hora 
                          key={clase.id}
                          clase={clase}
                          checked={clases.includes(clase.id)}
                          onChange={(e)=> handleClaseClick(e, clase.id)}
                        />
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              ))}
            </div>
          </React.Fragment>
        ))}
      </DialogContent>
      <DialogActions>
        <Button color='warning' onClick={onClose}>Cancelar</Button>
        <Button 
          disabled={!isSubmitEnabled} 
          color='error' 
          onClick={handleSubmit}
        >
          {clases.length === 0 && 'Borrar'}
          {clases.length === 1 && 'Borrar clase'}
          {clases.length > 1 && `Borrar ${clases.length} clases`}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalBorrarClasesProfesor