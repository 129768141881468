export const isEmail = (email)=> {
  const email_text = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
  return email_text.test(email)
}

export const isBlank = text => {
  if (!text) return true
  if (typeof text === 'string' && text.trim().length === 0) return true
  if (typeof text === 'object' && text.length === 0) return true
  if (typeof text === 'object' && Object.keys(text).length === 0) return true
  return false
}

export const isAnyBlank = (...texts) => texts.some(isBlank)

export const areBlank = (...texts) => texts.every(isBlank)

export const capitalize = text => (text || '').toString().trim().replace(/^\w/, c => c.toUpperCase())

export const normalizeLowerCase = text => {
  return (text || '')
    .toString()
    .trim()
    .toLowerCase()
    .replace(/á/g, 'a')
    .replace(/é/g, 'e')
    .replace(/í/g, 'i')
    .replace(/ó/g, 'o')
    .replace(/[úü]/g, 'u')
}

export const pluralize = (text, count) => {
  if (count === 1) return text
  return `${text}s`
}