import { Button, IconButton } from '@mui/material'
import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import PageLoading from '../../../../components/PageLoading/PageLoading'
import TableHeader from '../../../../components/TableHeader/TableHeader'
import { useNotification } from '../../../../context/NotificationManager/NotificationManager'
import MesRecienteSelector from '../../../../components/form/MesRecienteSelector/MesRecienteSelector'
import AcademiaSelector from '../../../../components/form/AcademiaSelector/AcademiaSelector'
import { getAuth } from '../../../../utils/auth'
import { dateToString, getMonthDays, monthToString } from '../../../../utils/date'
import Calendar from '../../../../components/Calendar/Calendar'
import { mergeHours } from '../../../../utils/model/horario'
import { addHorario, addHorarioDefault, addHorariosMes, deleteHorario, getHorariosMes, updateHorario } from '../../../../utils/api/horarios'
import ModalCrearHorario from '../../../../modals/ModalCrearHorario/ModalCrearHorario'
import ModalEditarHorario from '../../../../modals/ModalEditarHorario/ModalEditarHorario'
import css from './Aperturas.module.css'

const HorarioDia = ({ hours, onEdit, onDelete })=> {
  const horario = mergeHours(hours)
  return (
    <div className={css.day}>
      {horario.map((hora)=> (
        <div className={css.hour} key={hora}>
          {hora}
        </div>
      ))}
      <div className={css.buttons}>
        <IconButton size='small' color='secondary' onClick={onEdit}>
          <i className='material-icons'>edit</i>
        </IconButton>
        <IconButton size='small' color='error' onClick={onDelete}>
          <i className='material-icons'>delete</i>
        </IconButton>
      </div>
    </div>
  )
}

const HorarioVacio = ({ onAdd, onAddDefault })=> {
  return (
    <div className={css.day}>
      <div className={css.empty}>
        Sin horario
      </div>
      <div className={css.buttons}>
        <IconButton size='small' color='primary' onClick={onAddDefault}>
          <i className='material-icons'>library_add</i>
        </IconButton>
        <IconButton size='small' color='primary' onClick={onAdd}>
          <i className='material-icons'>add_box</i>
        </IconButton>
      </div>
    </div>
  )
}


const Aperturas = ()=> {

  const notification = useNotification()
  const queryClient = useQueryClient()

  const [mes, setMes] = useState(monthToString(new Date()))
  const [centro, setCentro] = useState(getAuth().centro)

  const [fechaCustomCreate, setFechaCustomCreate] = useState(null)
  const [fechaCustomEdit, setFechaCustomEdit] = useState(null)

  const { isLoading: isLoadingHorarios, data: horarios=[] } = useQuery({
    queryKey: ['planificacion', 'modelo_horario', centro, mes], 
    queryFn: ()=> getHorariosMes({ mes, centro })
      .then(datos=> (
        datos
          .map(({ fecha, ...rest })=> ({
            fecha: dateToString(fecha),
            ...rest
          }))
          .reduce((horarios, horario)=> ({
            ...horarios,
            [horario.fecha]: horario
          }), {})
      ))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los horarios', content: err })
        return []
      })
  })

  const { isFetching: isAddingHorariosMes, mutate: crearHorariosMes } = useMutation({
    mutationFn: addHorariosMes,
    onSuccess: ()=> {
      notification.success({ title: 'Horarios añadidos', content: 'Se han configurado los horarios del mes entero' })
      queryClient.invalidateQueries(['planificacion', 'modelo_horario', centro])
    },
    onError: err=> notification.error({ title: 'Error al añadir los horarios', content: err }),
  })

  const { isFetching: isAddingDefaultHorario, mutate: crearHorarioEstandar } = useMutation({
    mutationFn: addHorarioDefault,
    onSuccess: ()=> {
      notification.success({ title: 'Horario añadido', content: 'Se ha configurado el horario indicado' })
      queryClient.invalidateQueries(['planificacion', 'modelo_horario', centro])
    },
    onError: err=> notification.error({ title: 'Error al añadir el horario', content: err }),
  })

  const { isFetching: isAddingHorarioCustom, mutate: crearHorarioCustom } = useMutation({
    mutationFn: addHorario,
    onSuccess: ()=> {
      notification.success({ title: 'Horario añadido', content: 'Se ha configurado el horario indicado' })
      queryClient.invalidateQueries(['planificacion', 'modelo_horario', centro])
    },
    onError: err=> notification.error({ title: 'Error al añadir el horario', content: err }),
    onSettled: ()=> {
      setFechaCustomCreate(null)
    }
  })

  const { isFetching: isUpdatingHorario, mutate: actualizarHorario } = useMutation({
    mutationFn: updateHorario,
    onSuccess: ()=> {
      notification.success({ title: 'Horario actualizado', content: 'Se ha actualizado el horario indicado' })
      queryClient.invalidateQueries(['planificacion', 'modelo_horario', centro])
    },
    onError: err=> notification.error({ title: 'Error al actualizar el horario', content: err }),
    onSettled: ()=> {
      setFechaCustomEdit(null)
    }
  })

  const { isFetching: isDeletingHorario, mutate: borrarHorario } = useMutation({
    mutationFn: deleteHorario,
    onSuccess: ()=> {
      notification.success({ title: 'Horario borrado', content: 'Se ha borrado el horario seleccionado' })
      queryClient.invalidateQueries(['planificacion', 'modelo_horario', centro])
    },
    onError: err=> notification.error({ title: 'Error al eliminar el horario', content: err }),
  })

  const isUpdatingHorarios = (
    isAddingHorariosMes || isAddingDefaultHorario || 
    isAddingHorarioCustom || isUpdatingHorario || isDeletingHorario
  )

  const handleCrearMesEntero = ()=> {
    if (isUpdatingHorarios) return
    crearHorariosMes({
      mes,
      centro,
    })
  }

  const handleCreateHorarioDia = ({ horas })=> {
    if (isUpdatingHorarios) return
    crearHorarioCustom({ 
      centro, 
      horas,
      fecha: fechaCustomCreate, 
    })
  }

  const handleUpdateHorarioDia = ({ horas })=> {
    if (isUpdatingHorarios) return
    actualizarHorario({ 
      centro, 
      horas,
      fecha: fechaCustomEdit,
    })
  }

  const handleDeleteHorario = (id)=> {
    if (isUpdatingHorarios) return
    borrarHorario({ id })
  }

  const handleAddDefault = (fecha)=> {
    if (isUpdatingHorarios) return
    crearHorarioEstandar({ centro, fecha })
  }

  const events = getMonthDays(mes)
    .map(dateToString)
    .reduce((event_details, dia)=> {
      const horario = horarios[dia]
      if (horario) {
        return {
          ...event_details,
          [dia]: (
            <HorarioDia 
              id={horario.id} 
              hours={horario.horas} 
              onEdit={()=> setFechaCustomEdit(dia)}
              onDelete={()=> handleDeleteHorario(horario.id)}
            />
          )
        }
      }
      return {
        ...event_details,
        [dia]: (
          <HorarioVacio 
            onAdd={()=> setFechaCustomCreate(dia)}
            onAddDefault={()=> handleAddDefault(dia)}
          />
        )
      }
    }, {})

  return (
    <PageLoading isLoading={isLoadingHorarios}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <AcademiaSelector
              name='academia'
              label='Academia'
              value={centro}
              onChange={e=> setCentro(e.target.value)}
            />
            <MesRecienteSelector
              className={css.mes}
              name='mes'
              label='Mes'
              value={mes}
              onChange={e=> setMes(e.target.value)}
              forwardOptions={4}
              backwardOptions={4}
            />
            <Button variant='contained' onClick={handleCrearMesEntero}>
              Añadir mes entero
            </Button>
          </div>
        )}
        title='Horarios de apertura'
        showSearch={false}
      />
      <div className={css.calendar}>
        <Calendar month={mes} events={events} />
      </div>
      <ModalCrearHorario
        open={!!fechaCustomCreate}
        onClose={()=> setFechaCustomCreate(null)}
        onSubmit={handleCreateHorarioDia}
      />
      <ModalEditarHorario
        open={!!fechaCustomEdit}
        horario={(horarios[fechaCustomEdit] || {}).horas || []}
        onClose={()=> setFechaCustomEdit(null)}
        onSubmit={handleUpdateHorarioDia}
      />
    </PageLoading>
  )

}

export default Aperturas