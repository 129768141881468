import { IconButton } from '@mui/material'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import PageLoading from '../../../components/PageLoading/PageLoading'
import InformationTable from '../../../components/Table/InformationTable'
import TableHeader from '../../../components/TableHeader/TableHeader'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import { getCertificados } from '../../../utils/api/certificados'
import { downloadCSVFile, getCSVContent } from '../../../utils/csv'
import { dateToString } from '../../../utils/date'
import { dateSort, textFilter } from '../../../utils/table'

const Helpdesk = ()=> {

  const notification = useNotification()

  const [search, setSearch] = useState('')

  const { isLoading, data: certificadoAlumnoList=[] } = useQuery({
    queryKey: ['certificado_alumno', 'list'], 
    queryFn: ()=> getCertificados()
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los certificados', content: err })
        return []
      })
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Alumno', 'Centro', 'Fecha', 'Formación'],
      data: certificadoAlumnoList,
      transform: c=> ([
        c.alumno,
        c.centro,
        dateToString(c.fecha),
        c.formacion
      ])
    })
    downloadCSVFile(content, 'certificados')
  }

  const filterSearch = certificado=> {
    return textFilter({ 
      object: certificado, 
      fields: ['alumno', 'formacion', 'centro'],
      search: search
    })
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={(
          <IconButton size='small' color='primary' onClick={handleExport}>
            <i className='material-icons'>download</i>
          </IconButton>
        )}
        title='Certificados emitidos'
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        details={[
          { title: 'Alumno', key: 'alumno', align: 'left' },
          { title: 'Centro', key: 'centro', align: 'left' },
          { title: 'Fecha', key: 'fecha', sortFunction: dateSort, align: 'left' },
          { title: 'Formacion', key: 'formacion', sortDisabled: true, align: 'left' },
          { title: 'Opciones', key: 'opciones', sortDisabled: true, align: 'center' }
        ]}
        data={certificadoAlumnoList
          .filter(filterSearch)
          .map(certificado=> ({
            alumno: certificado.alumno,
            centro: certificado.centro,
            fecha: dateToString(certificado.fecha),
            formacion: certificado.formacion,
            opciones: (
              <IconButton size='small' color='primary' href={certificado.certificadoURL} target='_blank'>
                <i className='material-icons'>visibility</i>
              </IconButton>
            )
          }))
        }
      />
    </PageLoading>
  )

}

export default Helpdesk