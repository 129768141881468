
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import css from './ProvinciaSelector.module.css'

const PROVINCIAS = [
  'ÁLAVA','ALBACETE','ALICANTE', 'ALMERÍA', 'ASTURIAS', 'ÁVILA', 
  'BADAJOZ', 'BARCELONA', 'BURGOS', 
  'CÁCERES', 'CÁDIZ', 'CANTABRIA', 'CASTELLÓN', 'CIUDAD REAL', 'CÓRDOBA', 'LA CORUÑA', 'CUENCA', 
  'GERONA', 'GRANADA', 'GUADALAJARA', 'GUIPÚZCOA', 
  'HUELVA', 'HUESCA', 
  'ISLAS BALEARES', 
  'JAÉN', 
  'LEÓN', 'LÉRIDA', 'LUGO', 
  'MADRID', 'MÁLAGA', 'MURCIA', 
  'NAVARRA', 
  'ORENSE', 
  'PALENCIA', 'LAS PALMAS', 'PONTEVEDRA', 
  'LA RIOJA', 
  'SALAMANCA', 'SEGOVIA', 'SEVILLA', 'SORIA', 
  'TARRAGONA', 'SANTA CRUZ DE TENERIFE', 'TERUEL', 'TOLEDO', 
  'VALENCIA', 'VALLADOLID', 'VIZCAYA', 
  'ZAMORA', 'ZARAGOZA'
]

const ProvinciaSelector = ({ className, name, label, showAll, value, onChange })=> {

  return (
    <FormControl className={`${className} ${css.main}`} variant="standard">
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        labelId={name}
        id={name}
        value={value}
        onChange={onChange}
        label={label}
      >
        {showAll && <MenuItem value=''>Ver todo</MenuItem>}
        {PROVINCIAS.map(provincia=> <MenuItem key={provincia} value={provincia}>{provincia}</MenuItem> )}
      </Select>
    </FormControl>
  )

}

export default ProvinciaSelector