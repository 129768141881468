import { GET, PUT, DELETE, POST} from '../request'

export const getCodigosPromocionales = (mes) => GET('/api/core/datos/codigo-promocional/list', { mes })

export const getCodigosPromocionalesUsage = ({ mes, codigo }) => GET('/api/core/datos/codigo-promocional/list/usage', { mes, codigo })

export const getCodigosPromocionalesDinamicos = () => GET('/api/core/datos/codigo-promocional-dinamico/list')

export const createCodigoPromocionalDinamico = (data)=> POST('/api/core/datos/codigo-promocional-dinamico', data)

export const updateCodigoPromocionalDinamico = ({ id, nombre })=> PUT('/api/core/datos/codigo-promocional-dinamico', { id, nombre })

export const deleteCodigoPromocionalDinamico = (id)=> DELETE('/api/core/datos/codigo-promocional-dinamico', { id })