import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import css from './DocumentSelector.module.css'

const DOCUMENTOS = [
  { 
    value: '/api/core/interno/documento-csv/pending-invoices', 
    text: 'Facturas pendientes',
  },
  { 
    value: '/api/core/interno/documento-csv/pending-payments', 
    text: 'Leads pendientes' 
  },
  { 
    value: '/api/core/interno/documento-csv/pending-feedbacks', 
    text: 'Pagos modificados' 
  },
  { 
    value: '/api/core/interno/documento-csv/student-certificates', 
    text: 'Listado alumnos' 
  },
  {
    value: '/api/core/interno/documento-csv/feedback-analysis',
    text: 'Análisis ROI',
    monthSelection: true
  },
  {
    value: '/api/core/interno/documento-csv/accounting-analysis',
    text: 'Análisis P&L',
    monthSelection: true
  },
  {
    value: '/api/core/interno/documento-csv/cashflow-analysis',
    text: 'Análisis Cashflow',
    monthSelection: true
  },
  {
    value: '/api/core/interno/documento-csv/churning-students',
    text: 'Bajas alumnos',
    monthSelection: true
  }
]

const DocumentSelector = ({ className, name, label, value, onChange })=> {

  return (
    <FormControl className={`${className} ${css.main}`} variant="standard">
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        labelId={name}
        id={name}
        value={value}
        onChange={onChange}
        label={label}
      >
        {DOCUMENTOS.map(documento=> (
          <MenuItem key={documento.text} value={documento}>
            {documento.text}
          </MenuItem> 
        ))}
      </Select>
    </FormControl>
  )

}

export default DocumentSelector

