import { getAuth } from './auth'
import config from './config'

const getFetchOptions = (method)=> ({
  method,
  mode: 'cors',
  cache: 'no-cache',
  credentials: 'same-origin',
  headers: {
    Authorization: `Bearer ${getAuth().token}`
  }
})

const processRequest = ({ url, method })=> {
  return new Promise((resolve, reject)=> {
    fetch(url, getFetchOptions(method))
    .then(res=> res.json())
    .then(res=> {
      if (res.resultado === 'ok') {
        if (res.datos) resolve(res.datos)
        else resolve(res)
      }
      else reject(res.motivo)
    })
    .catch(()=> reject("No se ha podido procesar la petición"))
  })
}

export const GET = (path, data={})=> {
  const url = config.api + path
  const params = new URLSearchParams(data)
  return processRequest({ url: `${url}?${params}`, method: 'GET' })
}

export const POST = (path, data={})=> {
  const url = config.api + path
  const params = new URLSearchParams(data)
  return processRequest({ url: `${url}?${params}`, method: 'POST' })
}

export const PUT = (path, data={})=> {
  const url = config.api + path
  const params = new URLSearchParams({ ...data, _method: 'put' })
  return processRequest({ url: `${url}?${params}`, method: 'POST' })
}

export const DELETE = (path, data={})=> {
  const url = config.api + path
  const params = new URLSearchParams({ ...data, _method: 'delete' })
  return processRequest({ url: `${url}?${params}`, method: 'POST' })
}

export const getBasePath = ()=> config.api