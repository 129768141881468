import { GET, PUT } from '../request'

/*
 * Esto es una API genérica que abstrae de las llamadas a la API de contrataciones.
 * - Pago 
 * - Pago Tarifa
 * - Pago Intensivo
 * - Pago Pack Horas
 */

export const getFromAlumno = (contratacion, { alumno })=> GET(`/api/core/contabilidad/${contratacion}/list/alumno`, { alumno })

export const getContratacionDetails = (contratacion, { id }) => GET(`/api/core/contabilidad/${contratacion}/details`, { id })

export const addContratacionModificacion = ({ contratacion, id, justificacion, importe })=> {
  return PUT(`/api/core/contabilidad/${contratacion}`, { id, justificacion, importe })
}

export const getContratacionesMes = (contratacion, { mes, centro })=> GET(`/api/core/alumnos/contabilidad/${contratacion}/list/mes`, { mes, centro })