import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { IconButton } from '@mui/material'
import PageLoading from '../../../../components/PageLoading/PageLoading'
import TableHeader from '../../../../components/TableHeader/TableHeader'
import InformationTable from '../../../../components/Table/InformationTable'
import { useNotification } from '../../../../context/NotificationManager/NotificationManager'
import FechaSelector from '../../../../components/form/FechaSelector/FechaSelector'
import { getLeadEntrantes } from '../../../../utils/api/leads'
import { textFilter } from '../../../../utils/table'
import { downloadCSVFile, getCSVContent } from '../../../../utils/csv'
import { dateToDateTime, dateToString } from '../../../../utils/date'
import css from './LeadEntrantes.module.css'
import { getCurso } from '../../../../utils/model/alumnos'

const LeadEntrantes = () => {
  const [fecha, setFecha] = useState(dateToDateTime(new Date()))
  const [search, setSearch] = useState('')

  const notification = useNotification()

  const { data: leadEntrantesList = [], isLoading } = useQuery({
    queryKey: ['feedback', 'list', fecha],
    queryFn: () =>
      getLeadEntrantes({ fecha: dateToString(fecha) })
        .catch(error => {
          notification.error({ title: 'Error al recuperar los leads', content: error.message })
          return []
        })
  })
  const handleExport = () => {
    const content = getCSVContent({
      columns: [
        'Contacto',
        'Nombre contacto',
        'Email contacto',
        'Teléfono contacto',
        'Nombre alumno',
        'Email alumno',
        'Teléfono alumno',
        'Código postal',
        'Centro',
        'Asignaturas',
        'Estudios',
        'Objetivos',
        'Cómo nos ha conocidio',
        'Centro',
        'Teléfono entrante',
        'Forma de contacto',
        'Contactos',
        'Prueba',
        'Estado del lead'
      ],
      data: leadEntrantesList,
      transform: p => [
        p.contacto,
        p.nombreContacto,
        p.emailContacto,
        p.telefonoContacto,
        p.nombreAlumno,
        p.emailAlumno,
        p.telefonoAlumno,
        p.codigoPostal,
        p.centroEstudios,
        p.asignaturas.map(asignatura => asignatura.nombreCompleto).join(' + '),
        getCurso(p.curso, p.tipologiaAcademica),
        p.objetivos,
        p.comoConoce.replaceAll("_", " "), 
        p.centro, 
        p.telefonoEntrante, 
        p.formaContacto, 
        p.contactos.length, 
        p.contactos.filter(c => c.estadoFeedback === 'PRUEBA' && c.asistencia === 'VIENE').length > 0 ? 'Sí' : 'No', 
        (p.contactos[p.contactos.length - 1]?.estadoFeedback || '').replaceAll("_", " ")
      ]
    })
    downloadCSVFile(content, `lead-entrantes - ${dateToString(fecha)}`)
  }
  const filterSearch = leadEntrante => {
    return textFilter({
      object: leadEntrante,
      fields: ['nombreContacto', 'telefonoContacto', 'centro'],
      search: search
    })
  }
  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        title="Lead entrantes"
        actions={
          <div className={css.actions}>
            <IconButton size="small" color="primary" onClick={handleExport}>
              <i className="material-icons">download</i>
            </IconButton>
            <FechaSelector className={css.year} label="Fecha" value={fecha} onChange={setFecha} />
          </div>
        }
        search={search}
        onSearchChange={e => setSearch(e.target.value)}
      />
      <InformationTable
        details={[
          { title: 'Lead', key: 'lead', align: 'left' },
          { title: 'Centro', key: 'centro', align: 'left' },
          { title: 'Cómo conoce', key: 'comoConoce', align: 'left' },
          { title: 'Forma contacto', key: 'formaContacto', align: 'left' },
          { title: 'Estado', key: 'estadoLead', align: 'left' },
          { title: 'Contactos', key: 'numContactos', align: 'center' }
        ]}
        data={leadEntrantesList
          .filter(filterSearch)
          .map(lead => ({
            lead: lead.nombreAlumno ? lead.nombreAlumno : `${lead.nombreContacto} (${lead.contacto})`,
            centro: lead.centro,
            comoConoce: lead.comoConoce.replaceAll("_", " "),
            formaContacto: lead.formaContacto,
            estadoLead: (lead.contactos[lead.contactos.length - 1]?.estadoFeedback || '').replaceAll("_", " "),
            numContactos: lead.contactos.length
          }))}
      />
    </PageLoading>
  )
}

export default LeadEntrantes
