import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import css from './ModalBorrarIngreso.module.css'

const ModalBorrarIngreso = ({ open, onSubmit, onClose }) => (
  <Dialog onClose={onClose} open={open} fullWidth maxWidth='sm'>
    <DialogTitle>Confirmar borrado de ingreso</DialogTitle>
    <DialogContent className={css.main}>
      <DialogContentText className={css.intro}>
        Estás a punto de borrar un ingreso de <strong>mundo</strong>estudiante. Esta operacion es <strong>irreversible</strong>,
        por favor, asegúrate de que has elegido el ingreso correcto antes de proceder al borrado.
        <br />
        Solo podrás borrar el ingreso de un alumno si este no tiene una factura asociada, y no está asignado a ninguna
        contratación.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button color='error' onClick={onClose}>Cancelar</Button>
      <Button color='primary' onClick={onSubmit}>Borrar el ingreso</Button>
    </DialogActions>
  </Dialog>
)

export default ModalBorrarIngreso