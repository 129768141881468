
export const getTotalBalance = (balances)=> {
  const balanceTotal = balances.reduce((total, balance)=> {
    total.ventas += balance.ventas
    total.ventaTeorica += balance.ventaTeorica
    total.deuda += balance.deuda
    total.deudaMes += balance.deudaMes
    total.ingresos += balance.ingresos
    total.ingresosDesasignados += balance.ingresosDesasignados
    return total
  }, {
    ventas: 0,
    ventaTeorica: 0,
    deuda: 0,
    deudaMes: 0,
    ingresos: 0,
    ingresosDesasignados: 0,
    resultado: 0
  })
  // Re-obtain the result
  balanceTotal.resultado = balanceTotal.ventaTeorica ? 100 * (balanceTotal.deudaMes || 0)/balanceTotal.ventaTeorica : null
  // Set the info to "Total"
  balanceTotal.centro = 'Total'
  balanceTotal.fecha = 'Total'
  return balanceTotal
}