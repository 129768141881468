import { IconButton } from '@mui/material'
import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { format, formatForExport } from '../../../../utils/math'
import { downloadCSVFile, getCSVContent } from '../../../../utils/csv'
import PageLoading from '../../../../components/PageLoading/PageLoading'
import InformationTable from '../../../../components/Table/InformationTable'
import TableHeader from '../../../../components/TableHeader/TableHeader'
import { useNotification } from '../../../../context/NotificationManager/NotificationManager'
import { dateToString } from '../../../../utils/date'
import AlumnoSelector from '../../../../components/form/AlumnoSelector/AlumnoSelector'
import { deleteIngreso, getFromAlumno, unassignIngreso, updateIngreso } from '../../../../utils/api/ingresos'
import ModalBorrarIngreso from '../../../../modals/ModalBorrarIngreso/ModalBorrarIngreso'
import ModalDesasignarIngreso from '../../../../modals/ModalDesasignarIngreso/ModalDesasignarIngreso'
import ModalEditarIngreso from '../../../../modals/ModalEditarIngreso/ModalEditarIngreso'
import css from './Ingresos.module.css'

const Ingresos = ()=> {

  const notification = useNotification()
  const queryClient = useQueryClient()

  const [alumno, setAlumno] = useState({})

  const [ingreso, setIngreso] = useState(null)
  const [action, setAction] = useState(null)

  const handleIngresoAction = (ingreso, action)=> {
    setIngreso(ingreso)
    setAction(action)
  }

  const { isLoading: isLoadingIngresos, data: ingresoList=[] } = useQuery({
    queryKey: ['ingresos', 'list', alumno.id], 
    enabled: alumno && !!alumno.id,
    queryFn: ()=> getFromAlumno({ alumno: alumno.id })
      .then(datos=> datos)
      .catch(err=> notification.error({ title: 'Error al recuperar los ingresos', content: err }))
  })

  const { isFetching: isIngresoUpdating, mutate: actualizarIngreso } = useMutation({
    mutationFn: updateIngreso,
    onSuccess: ()=> {
      notification.success({ title: 'Ingreso actualizado', content: 'Se ha actualizado el ingreso' })
      queryClient.invalidateQueries(['ingresos', 'list', alumno.id])
      handleIngresoAction(null, null)
    },
    onError: err=> notification.error({ title: 'Error al actualizar el ingreso', content: err }),
  })

  const { isFetching: isIngresoUnassigning, mutate: desasignarIngreso } = useMutation({
    mutationFn: unassignIngreso,
    onSuccess: ()=> {
      notification.success({ title: 'Ingreso desasignado', content: 'Se ha desasignado el ingreso' })
      handleIngresoAction(null, null)
    },
    onError: err=> notification.error({ title: 'Error al desasignar el ingreso', content: err }),
  })

  const { isFetching: isIngresoDeleting, mutate: borrarIngreso } = useMutation({
    mutationFn: deleteIngreso,
    onSuccess: ()=> {
      notification.success({ title: 'Ingreso borrado', content: 'Se han borrado el ingreso seleccionado' })
      queryClient.invalidateQueries(['ingresos', 'list', alumno.id])
      handleIngresoAction(null, null)
    },
    onError: err=> notification.error({ title: 'Error al eliminar el ingreso', content: err }),
  })

  const handleUpdate = (ingreso)=> {
    if (isIngresoUpdating || isIngresoUnassigning || isIngresoDeleting) return
    actualizarIngreso(ingreso)
  }

  const handleUnassign = ()=> {
    if (isIngresoUpdating || isIngresoUnassigning || isIngresoDeleting) return
    desasignarIngreso({ id: ingreso.id })
  }

  const handleDelete = ()=> {
    if (isIngresoUpdating || isIngresoUnassigning || isIngresoDeleting) return
    borrarIngreso({ id: ingreso.id })
  }

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Concepto', 'Fecha', 'Hora', 'Importe', 'Forma Pago', 'Centro' ],
      data: ingresoList,
      transform: ingreso=> ([
        ingreso.concepto,
        dateToString(ingreso.fecha),
        ingreso.hora,
        `${formatForExport(ingreso.importe)} €`,
        ingreso.formaPago,
        ingreso.centro
      ])
    })
    downloadCSVFile(content, 'ingresos-alumno')
  }

  return (
    <PageLoading isLoading={isLoadingIngresos}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <AlumnoSelector
              name='alumno'
              label='Alumno'
              value={alumno}
              onChange={(_e, value)=> setAlumno(value)}
            />
            <IconButton className={css.download} size='small' color='primary' onClick={handleExport}>
              <i className='material-icons'>download</i>
            </IconButton>
          </div>
        )}
        title='Ingresos'
        showSearch={false}
      />
      <InformationTable
        details={[
          { title: 'Concepto', key: 'concepto', sortDisabled: true, align: 'left' },
          { title: 'Fecha', key: 'fecha', sortDisabled: true, align: 'center' },
          { title: 'Hora', key: 'hora', sortDisabled: true, align: 'center' },
          { title: 'Importe', key: 'importe', sortDisabled: true, align: 'center' },
          { title: 'Forma Pago', key: 'formaPago', sortDisabled: true, align: 'center' },
          { title: 'Centro', key: 'centro', sortDisabled: true, align: 'center' },
          { title: 'Opciones', key: 'opciones', sortDisabled: true, align: 'center' },
        ]}
        data={ingresoList
          .map(ingreso=> ({
            concepto: ingreso.concepto,
            fecha: `${dateToString(ingreso.fecha)}`,
            hora: ingreso.hora,
            importe: `${format(ingreso.importe)} €`,
            formaPago: ingreso.formaPago,
            centro: ingreso.centro,
            opciones: (
              <>
                <IconButton size='small' color='primary' onClick={()=> handleIngresoAction(ingreso, 'unassign')}>
                  <i className='material-icons'>assignment_return</i>
                </IconButton>
                <IconButton size='small' color='secondary' onClick={()=> handleIngresoAction(ingreso, 'edit')}>
                  <i className='material-icons'>edit</i>
                </IconButton>
                <IconButton size='small' color='error' onClick={()=> handleIngresoAction(ingreso, 'delete')}>
                  <i className='material-icons'>delete</i>
                </IconButton>
              </>
            )
          }))
        }
      />
      <ModalEditarIngreso
        open={action === 'edit'} 
        ingreso={ingreso}
        onSubmit={handleUpdate} 
        onClose={()=> handleIngresoAction(null, null)}
      />
      <ModalDesasignarIngreso
        open={action === 'unassign'} 
        onSubmit={handleUnassign} 
        onClose={()=> handleIngresoAction(null, null)}
      />
      <ModalBorrarIngreso
        open={action === 'delete'} 
        onSubmit={handleDelete} 
        onClose={()=> handleIngresoAction(null, null)} 
      />
    </PageLoading>
  )

}

export default Ingresos