import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import css from './ModalBorrarCodigoPromocional.module.css'

const ModalBorrarCodigoPromocional = ({ open, onSubmit, onClose }) => (
  <Dialog onClose={onClose} open={open} fullWidth maxWidth='sm'>
  <DialogTitle>Confirmar borrado de código promocional</DialogTitle>
  <DialogContent className={css.main}>
    <DialogContentText className={css.intro}>
    ¿Seguro que quieres borrar este código promocional?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button color='error' onClick={onClose}>Cancelar</Button>
    <Button color='primary' onClick={onSubmit}>Borrar</Button>
  </DialogActions>
</Dialog>
)

export default ModalBorrarCodigoPromocional
