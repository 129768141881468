import React from 'react'
import { dateToString, getMonthDaysInfo, isSameDay, WEEKDAYS } from '../../utils/date'
import css from './Calendar.module.css'

const Calendar = ({ month, events })=> {

  const active_month = month.split('/')[0]-1
  const weeks = getMonthDaysInfo(month)
  const weekdays = [...WEEKDAYS.slice(1), WEEKDAYS[0]]

  return (
      <table className={css.calendar}>
        <thead>
          <tr>
            {weekdays.map(weekday=> (
              <th 
                className={css.column}
                key={weekday}
              >
                {weekday}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {weeks.map((week, i)=> (
            <tr className={css.row} key={i}>
              {week.map((day, j)=> (
                <td 
                  className={css.cell}
                  data-calendar-month={day.getMonth() === active_month ? 'same' : 'other'}
                  data-calendar-today={isSameDay(day, new Date()) ? 'yes' : 'no'}
                  key={j}
                >
                  <span className={css.date}>{day.getDate()}</span>
                  {events[dateToString(day)]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
  )
}

export default Calendar