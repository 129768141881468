import { GET, POST, PUT, DELETE } from '../request'

export const getHorariosMes = ({ mes, centro })=> GET('/api/core/planificacion/modelo-horario/list', { mes, centro })

export const addHorario = ({ centro, fecha, horas })=> POST('/api/core/planificacion/modelo-horario', { centro, fecha, 'horas[]': horas })

export const addHorarioDefault = ({ centro, fecha })=> POST('/api/core/planificacion/modelo-horario', { centro, fecha, horario_normal: true })

export const addHorariosMes = ({ mes, centro })=> POST('/api/core/planificacion/modelo-horario/month', ({ mes, centro }))

export const updateHorario = ({ centro, fecha, horas })=> PUT('/api/core/planificacion/modelo-horario', { centro, fecha, 'horas[]': horas })

export const deleteHorario = ({ id })=> DELETE('/api/core/planificacion/modelo-horario', { id })
