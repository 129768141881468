import { useQuery } from '@tanstack/react-query'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { getCodigosPromocionales } from '../../../utils/api/codigosPromocionales'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'


const CodigoPromocionalSelector = ({
  mes,
  name,
  label,
  className = '',
  value,
  onChange
}) => {

  const notification = useNotification()

  const { data: codigosPromocionales = [] } = useQuery({
    queryKey: ['codigo-promocional', 'list', mes], 
    enabled: !!mes,
    queryFn: () => getCodigosPromocionales(mes)
      .then(datos => datos)
      .catch(e=> {
        notification.error({ title: 'Error al obtener códigos promocionales', content: e })
        return []
      })
  })

  const listaCodigos = codigosPromocionales.map(codigo => codigo.nombre).sort()
  
  return (
    <FormControl
      variant='standard'
      className={className}
      fullWidth
    >
      <InputLabel>{label}</InputLabel>
      <Select
        name={name}
        labelId={name}
        value={value}
        onChange={onChange}
        label={label}
      >
        {listaCodigos.map((option, i) => (
          <MenuItem key={i} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default CodigoPromocionalSelector
