import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import css from './CompatibilidadSelector.module.css'

const COMPATIBILIDAD_OPCIONES = [
  { value: 'PAGO', label: 'Pagos' },
  { value: 'TARIFA', label: 'Tarifas' },
  { value: 'INTENSIVO', label: 'Intensivo' },
  { value: 'PACK_HORAS', label: 'Packs de horas' },
]

const CompatibilidadSelector = ({ 
  className = '', 
  name, 
  label, 
  value = [], 
  onChange 
}) => (
  <FormControl className={`${className} ${css.main}`} variant="standard">
    <InputLabel id={name}>{label}</InputLabel>
    <Select
      multiple
      labelId={name}
      name={name}
      id={name}
      value={value}
      onChange={onChange}
      label={label}
    >
      {COMPATIBILIDAD_OPCIONES.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default CompatibilidadSelector

