import { IconButton } from '@mui/material'
import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { format, formatForExport } from '../../../utils/math'
import { downloadCSVFile, getCSVContent } from '../../../utils/csv'
import PageLoading from '../../../components/PageLoading/PageLoading'
import InformationTable from '../../../components/Table/InformationTable'
import TableHeader from '../../../components/TableHeader/TableHeader'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import MesRecienteSelector from '../../../components/form/MesRecienteSelector/MesRecienteSelector'
import AcademiaSelector from '../../../components/form/AcademiaSelector/AcademiaSelector'
import { getAuth } from '../../../utils/auth'
import { dateToString, monthToString } from '../../../utils/date'
import { textFilter } from '../../../utils/table'
import { deleteFactura, getFacturasMes, updateFactura } from '../../../utils/api/facturas'
import ModalBorrarFactura from '../../../modals/ModalBorrarFactura/ModalBorrarFactura'
import ModalEditarFactura from '../../../modals/ModalEditarFactura/ModalEditarFactura'
import { getFactura } from '../../../utils/documents'
import css from './Facturas.module.css'

const Facturas = ()=> {

  const notification = useNotification()
  const queryClient = useQueryClient()

  const [mes, setMes] = useState(monthToString(new Date()))
  const [centro, setCentro] = useState(getAuth().centro)
  const [search, setSearch] = useState('')

  const [facturaDelete, setFacturaDelete] = useState(null)
  const [facturaEdit, setFacturaEdit] = useState(null)

  const { isLoading: isLoadingFacturas, data: facturaList=[] } = useQuery({
    queryKey: ['facturas', 'list', mes, centro], 
    queryFn: ()=> getFacturasMes({ mes, centro })
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las facturas', content: err })
        return []
      })
  })

  const { isFetching: isDeletingFactura, mutate: borrarFactura } = useMutation({
    mutationFn: deleteFactura,
    onSuccess: ()=> {
      notification.success({ title: 'Factura anulada', content: 'Se ha anulado la factura correctamente' })
      queryClient.invalidateQueries(['facturas', 'list', mes, centro])
    },
    onError: err=> notification.error({ title: 'Error al anular la factura', content: err }),
    onSettled: ()=> {
      setFacturaDelete(null)
    }
  })

  const { isFetching: isUpdatingFactura, mutate: actualizarFactura } = useMutation({
    mutationFn: updateFactura,
    onSuccess: ()=> {
      notification.success({ title: 'Factura actualizada', content: 'Se ha actualizado la factura correctamente' })
      queryClient.invalidateQueries(['facturas', 'list', mes, centro])
    },
    onError: err=> notification.error({ title: 'Error al actualizar la factura', content: err }),
    onSettled: ()=> {
      setFacturaEdit(null)
    }
  })


  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Alumno', 'Código', 'Fecha', 'Importe', 'Factura → Cliente', 'Factura → NIF', 'Factura → Concepto' ],
      data: facturaList,
      transform: c=> ([
        c.nombreAlumno,
        c.codigo,
        dateToString(c.fecha),
        formatForExport(c.importe),
        c.titularFactura,
        c.identificacionCliente,
        c.concepto
      ])
    })
    downloadCSVFile(content, 'facturas-mes')
  }

  const filterSearch = factura=> {
    return textFilter({ 
      object: factura, 
      fields: ['nombre', 'concepto', 'codigo'],
      search: search
    })
  }

  const handleDeleteFactura = ()=> {
    borrarFactura({ id: facturaDelete })
  }

  const handleUpdateFactura = (factura)=> {
    if (isDeletingFactura || isUpdatingFactura) return
    actualizarFactura(factura)
  }

  return (
    <PageLoading isLoading={isLoadingFacturas}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <IconButton className={css.download} size='small' color='primary' onClick={handleExport}>
              <i className='material-icons'>download</i>
            </IconButton>
            <AcademiaSelector
              name='academia'
              label='Academia'
              value={centro}
              onChange={e=> setCentro(e.target.value)}
            />
            <MesRecienteSelector
              className={css.mes}
              name='mes'
              label='Mes'
              value={mes}
              onChange={e=> setMes(e.target.value)}
            />
          </div>
        )}
        title='Resultados'
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        details={[
          { title: 'Alumno', key: 'alumno', align: 'left' },
          { title: 'Código', key: 'codigo', align: 'left' },
          { title: 'Fecha', key: 'fecha', align: 'left' },
          { title: 'Importe', key: 'importe', align: 'center' },
          { title: 'Opciones', key: 'opciones', sortDisabled: true, align: 'center' },
        ]}
        data={facturaList
          .filter(filterSearch)
          .map(factura=> ({
            alumno: factura.nombreAlumno,
            codigo: factura.codigo,
            fecha: dateToString(factura.fecha),
            importe: `${format(factura.importe)} €`,
            opciones: (
              <>
                <IconButton 
                  size='small' 
                  color='primary' 
                  href={getFactura({
                    academia: factura.centro, 
                    numero_factura: factura.codigo,
                    cliente: factura.titularFactura,
                    nif: factura.identificacionCliente,
                    referencia: factura.id,
                    fecha_emision: factura.fecha,
                    concepto: factura.concepto,
                    importe: formatForExport(factura.importe),
                  })}  
                  target='_blank'
                >
                  <i className='material-icons'>visibility</i>
                </IconButton>
                <IconButton size='small' color='secondary' onClick={()=> setFacturaEdit(factura)}>
                  <i className='material-icons'>edit</i>
                </IconButton>
                <IconButton size='small' color='error' onClick={()=> setFacturaDelete(factura.id)}>
                  <i className='material-icons'>delete</i>
                </IconButton>
              </>
            )
          }))
        }
      />
      <ModalBorrarFactura
        open={!!facturaDelete}
        onClose={()=> setFacturaDelete(null)}
        onSubmit={handleDeleteFactura}
      />
      <ModalEditarFactura
        open={!!facturaEdit}
        factura={facturaEdit}
        onClose={()=> setFacturaEdit(null)}
        onSubmit={handleUpdateFactura}
      />
    </PageLoading>
  )

}

export default Facturas