import { useEffect, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material"
import { isBlank } from "../../utils/string"
import ProvinciaSelector from "../../components/form/ProvinciaSelector/ProvinciaSelector"
import css from './ModalEditarCentroEstudios.module.css'

const ModalEditarCentroEstudios = ({ centroEstudios, open, onSubmit, onClose }) => {

  const [centroEstudiosUpdate, setCentroEstudiosUpdate] = useState({
    id: '',
    nombre: '',
    provincia: '',
  })

  useEffect(()=> {
    if (open) return
    setCentroEstudiosUpdate({
      id: '',
      nombre: '',
      provincia: '',
    })
  } , [open])
  
  useEffect(()=> {
    if (!centroEstudios || !centroEstudios.id) return
    if (centroEstudiosUpdate.id !== centroEstudios.id) {
      setCentroEstudiosUpdate({
        id: centroEstudios.id,
        nombre: centroEstudios.nombre,
        provincia: centroEstudios.provincia,
      })
    }
  }, [centroEstudiosUpdate.id, centroEstudios])

  const isSubmitEnabled = (
    !isBlank(centroEstudiosUpdate.id) &&
    !isBlank(centroEstudiosUpdate.nombre) && 
    !isBlank(centroEstudiosUpdate.provincia)
  )

  const handleSubmit = () => {
    onSubmit({ 
      id: centroEstudiosUpdate.id,
      nombre: centroEstudiosUpdate.nombre,
      provincia: centroEstudiosUpdate.provincia,
    })
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Editar centro de estudios</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Puedes modificar un centro de estudios actualizando el siguiente formulario.
        </DialogContentText>
        <TextField
          className={css.input}
          label="Nombre"
          value={centroEstudiosUpdate.nombre}
          onChange={e=> setCentroEstudiosUpdate({ ...centroEstudiosUpdate, nombre: e.target.value })}
          variant='standard'
        />
        <ProvinciaSelector
          className={css.input}
          name='provincia'
          label='Provincia'
          value={centroEstudiosUpdate.provincia}
          onChange={e=> setCentroEstudiosUpdate({ ...centroEstudiosUpdate, provincia: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>Cancelar</Button>
        <Button disabled={!isSubmitEnabled} color='primary' onClick={handleSubmit}>Editar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEditarCentroEstudios