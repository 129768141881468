import { useState } from "react"
import { dateFromMonthString, dateFromString, dateToString, isSameMonth, monthToString } from "../../../../utils/date"
import { getAuth } from "../../../../utils/auth"
import { getListFromCentro } from "../../../../utils/api/clasesProfesor"
import { useQuery } from "@tanstack/react-query"
import { useNotification } from "../../../../context/NotificationManager/NotificationManager"
import PageLoading from "../../../../components/PageLoading/PageLoading"
import TableHeader from "../../../../components/TableHeader/TableHeader"
import AcademiaSelector from "../../../../components/form/AcademiaSelector/AcademiaSelector"
import MesRecienteSelector from "../../../../components/form/MesRecienteSelector/MesRecienteSelector"
import Calendar from "../../../../components/Calendar/Calendar"
import { mergeClases } from "../../../../utils/model/horario"
import css from './Clases.module.css'

const ClaseCalendario = ({ hora, profesor, dimmed })=> (
  <div className={css.calendarEvent} data-status={dimmed ? 'dimmed' : 'normal'}>
    <p className={css.calendarEventRow}>
      <i className='material-icons'>3p</i> {profesor}
    </p>
    <p className={css.calendarEventRow}>
      <i className='material-icons'>schedule</i> {hora}
    </p>
  </div>
)

const Clases = ()=> {
  const notification = useNotification()

  const [mes, setMes] = useState(monthToString(new Date()))
  const [centro, setCentro] = useState(getAuth().centro)

  const { isLoading: isLoadingHorarios, data: clasesList=[] } = useQuery({
    queryKey: ['planificacion', 'clase-profesor', 'list', 'centro', centro, mes], 
    queryFn: ()=> getListFromCentro({ mes, centro })
      .then(datos=> (
        datos.map(({ fecha, ...rest })=> ({
          fecha: dateToString(fecha),
          ...rest
        }))
      ))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las clases', content: err })
        return []
      })
  })

  const clasesCalendario = mergeClases(clasesList)
  .map(clasesDia=> ({
    fecha: clasesDia.fecha,
    clases: clasesDia.clases.map(clase=> (
      <ClaseCalendario
        key={clase.id}
        hora={clase.hora}
        profesor={clase.profesor}
        dimmed={!isSameMonth(dateFromString(clasesDia.fecha), dateFromMonthString(mes))}
      />
    ))
  }))
  .reduce((acc, clasesDia)=> ({
      ...acc,
      [clasesDia.fecha]: clasesDia.clases
    }
  ), {})

  return (
    <PageLoading isLoading={isLoadingHorarios}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <AcademiaSelector
              name='academia'
              label='Academia'
              value={centro}
              onChange={e=> setCentro(e.target.value)}
            />
            <MesRecienteSelector
              className={css.mes}
              name='mes'
              label='Mes'
              value={mes}
              onChange={e=> setMes(e.target.value)}
              forwardOptions={4}
              backwardOptions={6}
            />
          </div>
        )}
        title='Horarios de profesores'
        showSearch={false}
      />
      <div className={css.calendar}>
        <p className={css.calendarMonth}>
          {mes}
        </p>
        <Calendar
          month={mes}
          events={clasesCalendario}
        />
      </div>
    </PageLoading>
  )
}

export default Clases