import { IconButton } from '@mui/material'
import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { format } from '../../../utils/math'
import { downloadCSVFile, getCSVContent } from '../../../utils/csv'
import PageLoading from '../../../components/PageLoading/PageLoading'
import InformationTable from '../../../components/Table/InformationTable'
import TableHeader from '../../../components/TableHeader/TableHeader'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import MesRecienteSelector from '../../../components/form/MesRecienteSelector/MesRecienteSelector'
import AcademiaSelector from '../../../components/form/AcademiaSelector/AcademiaSelector'
import { getAuth } from '../../../utils/auth'
import { dateToString, getDateWeekday, monthToString } from '../../../utils/date'
import ModalEditarCaja from '../../../modals/ModalEditarCaja/ModalEditarCaja'
import { getCajas, updateCaja } from '../../../utils/api/cajas'
import css from './Cajas.module.css'

const Abierta = ()=> (
  <span className={css.abierta}>Abierta</span>
)

const Descuadre = ({ descuadre })=> {
  if (!descuadre) return '--'
  if (descuadre > 0) return (
    <span className={css.descuadre} data-status='positive'>
      +{format(descuadre)} €
    </span>
  )
  else return (
    <span className={css.descuadre} data-status='negative'>
      {format(descuadre)} €
    </span>
  )
}

const Cajas = ()=> {

  const notification = useNotification()
  const queryClient = useQueryClient()

  const [mes, setMes] = useState(monthToString(new Date()))
  const [centro, setCentro] = useState(getAuth().centro)

  const [cajaEdit, setCajaEdit] = useState(null)

  const { isLoading: isLoadingCajas, data: cajaList=[] } = useQuery({
    queryKey: ['cajas', 'list', mes, centro], 
    queryFn: ()=> getCajas({ mes, centro })
      .then(datos=> datos.map(caja=> ({
        ...caja,
        descuadre: caja.balanceFinalTeorico ? (caja.balanceFinalReal || 0) - caja.balanceFinalTeorico : null,
      })))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las cajas', content: err })
        return []
      })
  })

  const { isFetching: isUpdatingCaja, mutate: actualizarCaja } = useMutation({
    mutationFn: updateCaja,
    onSuccess: ()=> {
      notification.success({ title: 'Caja actualizada', content: 'La caja ha sido actualizada correctamente' })
      queryClient.invalidateQueries(['cajas', 'list', mes, centro])
      setCajaEdit(null)
    },
    onError: err=> notification.error({ title: 'Error al actualizar la caja', content: err }),
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Fecha', 'Día Semana', 'Horario', 'Cierre Teórico', 'Cierre Real', 'Descuadre', 'Estado', 'Observaciones' ],
      data: cajaList,
      transform: caja=> ([
        dateToString(caja.fecha),
        getDateWeekday(caja.fecha),
        caja.horaCierre ? `${caja.horaApertura} - ${caja.horaCierre}` : `${caja.horaApertura} - Abierta`,
        caja.balanceFinalTeorico ? `${format(caja.balanceFinalTeorico)} €` : '--',
        caja.descuadre ? `${format(caja.descuadre)} €` : '--',
        caja.estado,
      ])
    })
    downloadCSVFile(content, 'cajas-mes')
  }

  const handleEditarCaja = caja=> {
    if (isUpdatingCaja) return
    actualizarCaja(caja)
  }

  return (
    <PageLoading isLoading={isLoadingCajas}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <AcademiaSelector
              name='academia'
              label='Academia'
              value={centro}
              onChange={e=> setCentro(e.target.value)}
            />
            <MesRecienteSelector
              className={css.mes}
              name='mes'
              label='Mes'
              value={mes}
              onChange={e=> setMes(e.target.value)}
            />
            <IconButton className={css.download} size='small' color='primary' onClick={handleExport}>
              <i className='material-icons'>download</i>
            </IconButton>
          </div>
        )}
        title='Cajas'
        showSearch={false}
      />
      <InformationTable
        details={[
          { title: 'Fecha', key: 'fecha', sortDisabled: true, align: 'left' },
          { title: 'Horario', key: 'horas', sortDisabled: true, align: 'center' },
          { title: 'Cierre', key: 'cierre_real', sortDisabled: true, align: 'center' },
          { title: 'Descuadre', key: 'descuadre', sortDisabled: true, align: 'center' },
          { title: 'Estado', key: 'estado', sortDisabled: true, align: 'center' },
          { title: 'Opciones', key: 'opciones', sortDisabled: true, align: 'center' },
        ]}
        data={cajaList
          .map(caja=> ({
            fecha: `${dateToString(caja.fecha)} (${getDateWeekday(caja.fecha)})`,
            horas: (
              <>
                {caja.horaApertura} - {caja.horaCierre ? caja.horaCierre : <Abierta />}
              </>
            ),
            cierre_real: caja.balanceFinalReal !== null ? `${format(caja.balanceFinalReal)} €` : '--',
            descuadre: <Descuadre descuadre={caja.descuadre} />,
            estado: caja.estado,
            opciones: (
              <IconButton size='small' color='secondary' onClick={()=> setCajaEdit(caja)}>
                <i className='material-icons'>edit</i>
              </IconButton>
            )
          }))
        }
      />
      <ModalEditarCaja
        open={!!cajaEdit}
        caja={cajaEdit}
        onSubmit={handleEditarCaja}
        onClose={()=> setCajaEdit(null)}
      />
    </PageLoading>
  )

}

export default Cajas