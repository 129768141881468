import { useState } from "react"
import { dateFromMonthString, dateFromString, dateToString, isSameMonth, monthToString } from "../../../../utils/date"
import { deleteFromIds, getListFromProfesor } from "../../../../utils/api/clasesProfesor"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useNotification } from "../../../../context/NotificationManager/NotificationManager"
import PageLoading from "../../../../components/PageLoading/PageLoading"
import TableHeader from "../../../../components/TableHeader/TableHeader"
import MesRecienteSelector from "../../../../components/form/MesRecienteSelector/MesRecienteSelector"
import Calendar from "../../../../components/Calendar/Calendar"
import { mergeClases } from "../../../../utils/model/horario"
import ProfesorSelector from "../../../../components/form/ProfesorSelector/ProfesorSelector"
import css from './Profesores.module.css'
import { Fab } from "@mui/material"
import ModalBorrarClasesProfesor from "../../../../modals/ModalBorrarClasesProfesor/ModalBorrarClasesProfesor"

const ClaseCalendario = ({ hora, centro, dimmed })=> (
  <div className={css.calendarEvent} data-status={dimmed ? 'dimmed' : 'normal'}>
    <p className={css.calendarEventRow}>
      <i className='material-icons'>school</i> {centro}
    </p>
    <p className={css.calendarEventRow}>
      <i className='material-icons'>schedule</i> {hora}
    </p>
  </div>
)

const Profesores = ()=> {

  const notification = useNotification()
  const queryClient = useQueryClient()

  const [mes, setMes] = useState(monthToString(new Date()))
  const [profesor, setProfesor] = useState('')

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)

  const { isLoading: isLoadingHorarios, data: clasesList=[] } = useQuery({
    queryKey: ['planificacion', 'clase-profesor', 'list', 'profesor', profesor, mes], 
    enabled: !!profesor,
    queryFn: ()=> getListFromProfesor({ mes, profesor })
      .then(datos=> (
        datos.map(({ fecha, ...rest })=> ({
          fecha: dateToString(fecha),
          ...rest
        }))
      ))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las clases', content: err })
        return []
      })
  })

  const { isFetching: isDeletingClases, mutate: borrarClases } = useMutation({
    mutationFn: deleteFromIds,
    onSuccess: ()=> {
      notification.success({ title: 'Clases borradas', content: 'Se han borrado las clases correctamente' })
      queryClient.invalidateQueries(['planificacion', 'clase-profesor', 'list'])
      setDeleteModalOpen(false)
    },
    onError: err=> notification.error({ title: 'Error al eliminar las clases', content: err }),
  })

  const clasesCalendario = mergeClases(clasesList)
  .map(clasesDia=> ({
    fecha: clasesDia.fecha,
    clases: clasesDia.clases.map(clase=> (
      <ClaseCalendario
        key={clase.id}
        hora={clase.hora}
        centro={clase.centro}
        dimmed={!isSameMonth(dateFromString(clasesDia.fecha), dateFromMonthString(mes))}
      />
    ))
  }))
  .reduce((acc, clasesDia)=> ({
      ...acc,
      [clasesDia.fecha]: clasesDia.clases
    }
  ), {})

  const handleDeleteClases = ({ clases })=> {
    if (isDeletingClases) return
    borrarClases({ ids: clases })
  }

  return (
    <PageLoading isLoading={isLoadingHorarios}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <ProfesorSelector
              className={css.input}
              name='profesor'
              label='Profesor'
              value={profesor}
              onChange={(_e, value)=> setProfesor(value?.id || '')}
            />
            <MesRecienteSelector
              className={css.mes}
              name='mes'
              label='Mes'
              value={mes}
              onChange={e=> setMes(e.target.value)}
              forwardOptions={4}
              backwardOptions={6}
            />
          </div>
        )}
        title='Horarios de profesor'
        showSearch={false}
      />
      <div className={css.calendar}>
        <p className={css.calendarMonth}>{mes}</p>
        <Calendar month={mes} events={clasesCalendario} />
      </div>
      {clasesList.length > 0 && (
        <Fab
          className={css.borrar}
          disabled={false}
          onClick={()=> setDeleteModalOpen(true)}
          size="medium"
          color="error" 
          aria-label="remove"
        >
          <i className='material-icons'>delete</i>
        </Fab>
      )}
      <ModalBorrarClasesProfesor
        open={isDeleteModalOpen}
        clasesDisponibles={clasesList}
        onSubmit={handleDeleteClases}
        onClose={()=> setDeleteModalOpen(false)}
      />
    </PageLoading>
  )
}

export default Profesores