import { IconButton } from '@mui/material'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import EjercicioSelector from '../../../../components/form/EjercicioSelector/EjercicioSelector'
import { format, formatForExport } from '../../../../utils/math'
import { downloadCSVFile, getCSVContent } from '../../../../utils/csv'
import MesSelector from '../../../../components/form/MesSelector/MesSelector'
import PageLoading from '../../../../components/PageLoading/PageLoading'
import InformationTable from '../../../../components/Table/InformationTable'
import TableHeader from '../../../../components/TableHeader/TableHeader'
import { useNotification } from '../../../../context/NotificationManager/NotificationManager'
import { getTotalBalance } from '../../../../utils/model/balance-mensual'
import { getBalancesMes } from '../../../../utils/api/balances'
import css from './Mes.module.css'

const Balance = ({ balance, revertir=false, sufijo='€' })=> {
  if (isNaN(balance)) return '--'
  if ((!revertir && balance >= 0) || (revertir && balance <= 0)) return (
    <span className={css.balance} data-status='positive'>
      {format(balance)} {sufijo}
    </span>
  )
  else return (
    <span className={css.balance} data-status='negative'>
      {format(balance)} {sufijo}
    </span>
  )
}

const Importe = ({ importe, sufijo='€' })=> (
  <span className={css.importe}>
    {format(importe)} {sufijo}
  </span>
)

const Mes = ()=> {

  const notification = useNotification()

  const [mes, setMes] = useState((new Date()).getMonth()+1)
  const [ejercicio, setEjercicio] = useState((new Date()).getFullYear())

  const { isLoading: isLoadingCentro, data: balanceList=[] } = useQuery({
    queryKey: ['balance-mensual', 'list', 'centro', mes, ejercicio],
    queryFn: ()=> getBalancesMes({ mes: `${mes}/${ejercicio}` })
      .then(datos=> datos.map(balance=> ({
        ...balance,
        resultado: balance.ventaTeorica ? 100 * (balance.deudaMes || 0)/balance.ventaTeorica : null
      })))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los balances mensuales', content: err })
        return []
      })
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Centro', 'Ventas', 'Servicios', 'Deuda Ventas', 'Deuda Servicios', 'Ingresos', 'Ingresos Desasignados', 'Resultado' ],
      data: balanceList,
      transform: balance=> ([
        balance.centro,
        formatForExport(balance.ventas),
        formatForExport(balance.ventaTeorica),
        formatForExport(balance.deuda),
        formatForExport(balance.deudaMes),
        formatForExport(balance.ingresos),
        formatForExport(balance.ingresosDesasignados),
        `${formatForExport(balance.resultado)} %`
      ])
    })
    downloadCSVFile(content, 'ventas-mes')
  }

  return (
    <PageLoading isLoading={isLoadingCentro}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <MesSelector
              name='mes'
              label='Mes'
              value={mes}
              onChange={e=> setMes(e.target.value)}
            />
            <EjercicioSelector
              className={css.year}
              name='ejercicio'
              label='Ejercicio'
              value={ejercicio}
              onChange={e=> setEjercicio(e.target.value)}
            />
            <IconButton className={css.download} size='small' color='primary' onClick={handleExport}>
              <i className='material-icons'>download</i>
            </IconButton>
          </div>
        )}
        title='Balances mensuales de mundoestudiante'
        showSearch={false}
      />
      <InformationTable
        details={[
          { title: 'Centro', key: 'centro', sortDisabled: true, align: 'left' },
          { title: 'Ventas', key: 'venta', sortDisabled: true, align: 'center' },
          { title: 'Deuda Ventas', key: 'deuda', sortDisabled: true, align: 'center' },
          { title: 'Servicios', key: 'venta_teorica', sortDisabled: true, align: 'center' },
          { title: 'Deuda Servicios', key: 'deuda_mes', sortDisabled: true, align: 'center' },
          { title: 'Ingresos', key: 'ingresos', sortDisabled: true, align: 'center' },
          { title: 'Desasignados', key: 'desasignados', sortDisabled: true, align: 'center' },
          { title: 'Resultado', key: 'resultado', sortDisabled: true, align: 'center' },
        ]}
        data={[...balanceList, getTotalBalance(balanceList)]
          .map(balance=> ({
            className: balance.fecha === "Total" ? css.totalRow : null,
            centro: balance.centro,
            venta: <Balance balance={balance.ventas} />,
            venta_teorica: <Balance balance={balance.ventaTeorica} />,
            deuda: <Balance revertir balance={balance.deuda} />,
            deuda_mes: <Balance revertir balance={balance.deudaMes} />,
            ingresos: <Importe importe={balance.ingresos} />,
            desasignados: <Importe importe={balance.ingresosDesasignados} />,
            resultado: <Balance revertir balance={balance.resultado} sufijo='%' />,
          }))
        }
      />
    </PageLoading>
  )

}

export default Mes