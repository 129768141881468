import { useEffect, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material"
import { isBlank } from "../../utils/string"
import css from './ModalEditarFactura.module.css'

const FACTURA_VACIA = {
  id: '',
  titularFactura: '',
  identificacionCliente: '',
  concepto: ''
}

const ModalEditarFactura = ({ open, factura: datosFactura=FACTURA_VACIA, onSubmit, onClose }) => {

  const [factura, setFactura] = useState(FACTURA_VACIA)

  useEffect(()=> {
    if (open) setFactura(datosFactura || FACTURA_VACIA)
  }, [open, datosFactura])

  const isSubmitEnabled = (
    !isBlank(factura.id) &&
    !isBlank(factura.titularFactura) && 
    !isBlank(factura.identificacionCliente) && 
    !isBlank(factura.concepto)
  )

  const handleSubmit = () => {
    onSubmit({
      id: factura.id,
      nombre: factura.titularFactura,
      identificacion_cliente: factura.identificacionCliente,
      concepto: factura.concepto,
    })
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Editar factura</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Puedes editar los datos de la factura de <strong>mundo</strong>estudiante 
          modificando los datos a continuación.
        </DialogContentText>
        <TextField
          className={css.input}
          label="Nombre del cliente"
          value={factura.titularFactura}
          onChange={e=> setFactura({ ...factura, titularFactura: e.target.value })}
          variant='standard'
        />
        <TextField
          className={css.input}
          label="Identificación del cliente (NIF / NIE)"
          value={factura.identificacionCliente}
          onChange={e=> setFactura({ ...factura, identificacionCliente: e.target.value })}
          variant='standard'
        />
        <TextField
          className={css.input}
          label="Concepto de la factura"
          value={factura.concepto}
          onChange={e=> setFactura({ ...factura, concepto: e.target.value })}
          variant='standard'
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>Cancelar</Button>
        <Button disabled={!isSubmitEnabled} color='primary' onClick={handleSubmit}>Actualizar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEditarFactura