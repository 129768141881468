import { IconButton } from '@mui/material'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { downloadCSVFile, getCSVContent } from '../../../../utils/csv'
import PageLoading from '../../../../components/PageLoading/PageLoading'
import InformationTable from '../../../../components/Table/InformationTable'
import TableHeader from '../../../../components/TableHeader/TableHeader'
import { useNotification } from '../../../../context/NotificationManager/NotificationManager'
import MesRecienteSelector from '../../../../components/form/MesRecienteSelector/MesRecienteSelector'
import { dateToFullString, monthToString } from '../../../../utils/date'
import { textFilter } from '../../../../utils/table'
import { getLeadsIncomingPhone } from '../../../../utils/api/leads'
import { capitalize } from '../../../../utils/string'
import css from './TelefonosEntrantes.module.css'

const TelefonosEntrantes = ()=> {

  const notification = useNotification()

  const [mes, setMes] = useState(monthToString(new Date()))
  const [search, setSearch] = useState('')

  const { isLoading: isLoadingTelefonos, data: telefonoList=[] } = useQuery({
    queryKey: ['feedback', 'list', 'incoming_phone', mes], 
    queryFn: ()=> getLeadsIncomingPhone({ mes })
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los teléfonos', content: err })
        return []
      })
  })


  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Contacto Lead', 'Alumno Lead', 'Centro', 'Centro origen', 'Teléfono Entrante', 'Cómo conoce', 'Primer contacto', 'Último contacto', 'Estado lead' ],
      data: telefonoList,
      transform: c=> ([
        c.contacto,
        c.alumno,
        c.centro,
        c.centro_origen,
        c.telefono_entrante,
        c.como_conoce,
        dateToFullString(c.primer_contacto),
        dateToFullString(c.ultimo_contacto),
        capitalize(c.estado.replaceAll('_', ' '))
      ])
    })
    downloadCSVFile(content, 'telefonos-entrantes')
  }

  const filterSearch = lead=> {
    return textFilter({ 
      object: lead, 
      fields: ['contacto', 'centro', 'estado'],
      search: search
    })
  }

  return (
    <PageLoading isLoading={isLoadingTelefonos}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <IconButton className={css.download} size='small' color='primary' onClick={handleExport}>
              <i className='material-icons'>download</i>
            </IconButton>
            <MesRecienteSelector
              className={css.mes}
              name='mes'
              label='Mes'
              value={mes}
              onChange={e=> setMes(e.target.value)}
            />
          </div>
        )}
        title='Resultados'
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        details={[
          { title: 'Lead', key: 'contacto', align: 'left' },
          { title: 'Centro', key: 'centro', align: 'left' },
          { title: 'Fecha', key: 'fecha', align: 'left' },
          { title: 'Teléfono Entrante', key: 'telefono', align: 'left' },
          { title: 'Estado Lead', key: 'estado', align: 'left' }
        ]}
        data={telefonoList
          .filter(filterSearch)
          .map(lead=> ({
            contacto: lead.alumno || lead.contacto,
            centro: lead.centro,
            fecha: dateToFullString(lead.primer_contacto),
            telefono: lead.telefono_entrante,
            estado: capitalize(lead.estado.replaceAll('_', ' '))
          }))
        }
      />
    </PageLoading>
  )

}

export default TelefonosEntrantes