import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import css from './ComoConoceSelector.module.css'

const COMO_CONOCE = [
  { value: 'FORMULARIO_WEB', label: 'Formulario web' },
  { value: 'PRUEBA_AUTOMATICA', label: 'Prueba automática' },
  { value: 'GOOGLE', label: 'Buscador de Google' },
  { value: 'CALLE', label: 'Tienda de calle' },
  { value: 'RECOMENDACION', label: 'Recomendación' },
  { value: 'FAMILIAR', label: 'Familiar' },
  { value: 'ANTIGUO_ALUMNO', label: 'Antiguo Alumno' },
  { value: 'GOOGLE_MAPS', label: 'Google Maps' },
  { value: 'FOLLETO', label: 'Folleto' },
  { value: 'FACEBOOK', label: 'Facebook' },
  { value: 'INSTAGRAM', label: 'Instagram' },
  { value: 'TIKTOK', label: 'TikTok' },
  { value: 'ACUERDOS_CORPORATIVOS', label: 'Acuerdos corporativos' },
  { value: 'OTRA', label: 'Otra' },
]

const ComoConoceSelector = ({ className, name, label, value, onChange })=> (
  <FormControl className={`${className} ${css.main}`} variant='standard'>
    <InputLabel id={name}>{label}</InputLabel>
    <Select
      name={name}
      labelId={name}
      id={name}
      value={value}
      onChange={onChange}
      label={label}
    >
      {COMO_CONOCE.map(como => (
        <MenuItem key={como.value} value={como.value}>
          {como.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default ComoConoceSelector
