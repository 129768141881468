import { Fab, IconButton } from '@mui/material'
import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import EjercicioSelector from '../../../components/form/EjercicioSelector/EjercicioSelector'
import PageLoading from '../../../components/PageLoading/PageLoading'
import InformationTable from '../../../components/Table/InformationTable'
import TableHeader from '../../../components/TableHeader/TableHeader'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import ModalCrearDocumento from '../../../modals/ModalCrearDocumento/ModalCrearDocumento'
import { createDocumento, getDocumento, getDocumentos } from '../../../utils/api/documentos'
import { dateToString } from '../../../utils/date'
import { dateSort, textFilter } from '../../../utils/table'
import { downloadCSVFile, getCSVContent } from '../../../utils/csv'
import css from './Documentos.module.css'

const Documentos = ()=> {

  const notification = useNotification()
  const queryClient = useQueryClient()

  const [ejercicio, setEjercicio] = useState((new Date()).getFullYear())
  const [activeDocument, setActiveDocument] = useState('')
  const [search, setSearch] = useState('')

  const [isCreateModalOpen, setCreateModalOpen] = useState(false)

  const { isLoading: isLoadingDocument} = useQuery({
    queryKey: ['documento_csv', activeDocument],
    enabled: !!activeDocument,
    queryFn: ()=> getDocumento({ id: activeDocument })
      .then(documento=> {
        const { titulo, datos, cabeceras } = documento
        const content = getCSVContent({ columns: cabeceras, data: datos })
        downloadCSVFile(content, titulo)
        return null
      })
      .catch(err=> {
        notification.error({ title: 'Error al recuperar el documento', content: err })
        return null
      })
  })

  const { isLoading, data: documentoList=[] } = useQuery({
    queryKey: ['documento_csv', 'list', ejercicio], 
    queryFn: ()=> getDocumentos({ ejercicio })
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los documentos', content: err })
        return []
      })
  })
  
  const { isFetching: isCreatingDocument, mutate: crearDocumento } = useMutation({
    mutationFn: createDocumento,
    onSuccess: ()=> {
      notification.success({ title: 'Documento creado', content: 'El documento está en proceso' })
      queryClient.invalidateQueries(['documento_csv', 'list', ejercicio])
      setCreateModalOpen(null)
    },
    onError: err=> notification.error({ title: 'Error al generar el documento', content: err }),
  })

  const handleNuevoDocumento = ({ url, mes })=> {
    if (isCreatingDocument) return
    crearDocumento({ url, mes })
  }

  const filterSearch = documento=> {
    return textFilter({ 
      object: documento, 
      fields: ['titulo', 'creador'],
      search: search
    })
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={(
          <EjercicioSelector
            name='ejercicio'
            label='Ejercicio'
            value={ejercicio}
            onChange={e=> setEjercicio(e.target.value)}
            minYear={2015}
          />
        )}
        title='Documentos generados'
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        size='small'
        details={[
          { title: 'Documento', key: 'documento', align: 'left' },
          { title: 'Fecha', key: 'fecha', sortFunction: dateSort, align: 'left' },
          { title: 'Creador', key: 'creador', align: 'left' },
          { title: 'Opciones', key: 'opciones', sortDisabled: true, align: 'center' }
        ]}
        data={documentoList
          .filter(filterSearch)
          .map(documento=> ({
            documento: documento.titulo,
            fecha: dateToString(documento.fecha),
            creador: documento.creador,
            opciones: (
              <IconButton 
                size='small' 
                color='primary' 
                onClick={()=> setActiveDocument(documento.id)}
                disabled={isLoadingDocument}
              >
                <i className='material-icons'>download</i>
              </IconButton>
            )
          }))
        }
      />
      <Fab
        className={css.nuevo}
        disabled={isCreatingDocument}
        onClick={()=> setCreateModalOpen(true)}
        size="medium"
        color="primary" 
        aria-label="add"
      >
        <i className='material-icons'>add</i>
      </Fab>
      <ModalCrearDocumento
        open={isCreateModalOpen}
        onSubmit={handleNuevoDocumento}
        onClose={()=> setCreateModalOpen(false)}
      />
    </PageLoading>
  )

}

export default Documentos