import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import css from './TipoAsignaturaSelector.module.css'

const TIPOS = [
  { value: 'LETRAS', label: 'Letras' },
	{ value: 'CIENCIAS', label: 'Ciencias' },
	{ value: 'CIENCIAS_SALUD', label: 'Ciencias de la salud' },
	{ value: 'IDIOMAS', label: 'Idiomas' },
	{ value: 'ARTES', label: 'Artes' },
	{ value: 'OTRAS', label: 'Otras' } 
]

const TipoAsignaturaSelector = ({ className, name, label, value, onChange })=> (
  <FormControl className={`${className} ${css.main}`} variant="standard">
    <InputLabel id={name}>{label}</InputLabel>
    <Select
      labelId={name}
      id={name}
      value={value}
      onChange={onChange}
      label={label}
    >
      {TIPOS.map(tipo=> (
        <MenuItem key={tipo.value} value={tipo.value}>{tipo.label}</MenuItem> 
      ))}
    </Select>
  </FormControl>
)

export default TipoAsignaturaSelector

