
export const mergeClases = (clases)=> {
  // Saca las fechas de las clases (quitamos duplicados)
  const fechas = clases
    .map(clase=> clase.fecha)
    .filter((fecha, i, arr)=> arr.indexOf(fecha) === i)
    .sort()
  // Agrupa las clases por fecha
  const clasesFecha = fechas.map(fecha=> ({
    fecha,
    clases: clases
      .filter(clase=> clase.fecha === fecha)
      .sort((a, b)=> {
        // Sort first by profesor, then by centro, then by hour
        if (a.profesor < b.profesor) return -1
        if (a.profesor > b.profesor) return 1
        if (a.centro < b.centro) return -1
        if (a.centro > b.centro) return 1
        if (a.hora < b.hora) return -1
        if (a.hora > b.hora) return 1
        return 0
      })
  }))
  // Fusionar las clases si tienen el mismo centro
  const clasesAgrupadas = clasesFecha.map(clasesDia=> ({
    ...clasesDia,
    clases: mergeClasesSameDay(clasesDia.clases)
  }))
  return clasesAgrupadas
}

export const mergeClasesSameDay = (clases)=> {
  return clases
    .reduce((clasesAgrupadas, clase)=> {
      if (clasesAgrupadas.length === 0) return [clase]
      const ultimaClase = clasesAgrupadas[clasesAgrupadas.length-1]
      // Combinamos si coinciden en hora y centro
      const horasConsecutivas = ultimaClase.hora.substr(-5) === clase.hora.substring(0, 5)
      const mismoProfesor = ultimaClase.profesorId === clase.profesorId
      if (horasConsecutivas && mismoProfesor) {
        clasesAgrupadas[clasesAgrupadas.length-1] = {
          ...ultimaClase,
          hora: `${ultimaClase.hora.substr(0, 5)}-${clase.hora.substr(-5)}`
        }
        return clasesAgrupadas
      }
      // Else get them both together
      return [...clasesAgrupadas, clase]
    }, [])
}

export const mergeHours = (hours)=> {
  return hours
    .sort()
    .reduce((combined_hours, hour)=> {
      if (combined_hours.length === 0) return [hour]
      const last_hour = combined_hours[combined_hours.length-1]
      // Combine the hours if they match
      if (last_hour.substr(-5) === hour.substring(0, 5)) {
        combined_hours[combined_hours.length-1] = `${last_hour.substr(0, 5)}-${hour.substr(-5)}`
        return combined_hours
      }
      // Else get them both together
      return [...combined_hours, hour]
    }, [])
}