import { downloadCSVFile, getCSVContent } from "../../../utils/csv"
import PageLoading from "../../../components/PageLoading/PageLoading"
import TableHeader from "../../../components/TableHeader/TableHeader"
import AcademiaSelector from "../../../components/form/AcademiaSelector/AcademiaSelector"
import MesRecienteSelector from "../../../components/form/MesRecienteSelector/MesRecienteSelector"
import { IconButton } from "@mui/material"
import { useState } from "react"
import { monthToString } from "../../../utils/date"
import { getAuth } from "../../../utils/auth"
import { useNotification } from "../../../context/NotificationManager/NotificationManager"
import { useQuery } from "@tanstack/react-query"
import { getContratacionesMes } from "../../../utils/api/contrataciones"
import { textFilter } from "../../../utils/table"
import InformationTable from "../../../components/Table/InformationTable"
import css from "./Contrataciones.module.css"
import Tabs from "../../../components/Tabs/Tabs"
import { getCurso } from "../../../utils/model/alumnos"

const PackHorasMes = ({ search, packs }) => {
  
  const filterSearch = contratacion => {
    return textFilter({
      object: contratacion,
      fields: ['alumno', 'pack'],
      search: search
    })
  }
  
  return (
    <InformationTable
      details={[
        { title: 'Alumno', key: 'alumno', align: 'left' },
        { title: 'Pack', key: 'pack', align: 'left' },
        { title: 'Horas', key: 'horas', align: 'left' },
        { title: 'Curso', key: 'curso', align: 'left' }
      ]}
      data={packs.filter(filterSearch).map(pack => ({
        alumno: pack.alumno,
        pack: pack.pack,
        horas: `${pack.horas}h`,
        curso: getCurso(pack.curso, pack.tipologiaAcademica)
      }))}
    />
  )
}

const TarifasMes = ({ search, tarifas }) => {
    
    const filterSearch = tarifa => {
      return textFilter({
        object: tarifa,
        fields: ['alumno', 'tarifa'],
        search: search
      })
    }
    
    return (
      <InformationTable
        details={[
          { title: 'Alumno', key: 'alumno', align: 'left' },
          { title: 'Tarifa', key: 'tarifa', align: 'left' },
          { title: 'Curso', key: 'curso', align: 'left' }
        ]}
        data={tarifas.filter(filterSearch).map(tarifa => ({
          alumno: tarifa.alumno,
          tarifa: tarifa.tarifa,
          curso: getCurso(tarifa.curso, tarifa.tipologiaAcademica)
        }))}
      />
    )
}

const Contrataciones = () => {

  const [mes, setMes] = useState(monthToString(new Date()))
  const [centro, setCentro] = useState(getAuth().centro)
  const [search, setSearch] = useState('')

  const notification = useNotification()

  const { data: packHorasList = [], isLoading: isLoadingPacks } = useQuery({
    queryKey: ['pack-horas-alumno', 'list', mes, centro],
    queryFn: () => getContratacionesMes('pack-horas-alumno', { mes, centro })
      .catch(error => {
        notification.error({ title: 'Error al recuperar los packs', content: error.message })
        return []
      })
  })

  const { data: tarifasList = [], isLoading: isLoadingTarifas } = useQuery({
    queryKey: ['tarifa-alumno', 'list', mes, centro],
    queryFn: () => getContratacionesMes('tarifa-alumno', { mes, centro })
      .catch(error => {
        notification.error({ title: 'Error al recuperar las tarifas', content: error.message })
        return []
      })
  })
  
  const handleExport = () => {
    const content = getCSVContent({
      columns: [
        'Alumno', 
        'Contratación', 
        'Curso',
        'Objetivo'
      ],
      data: [...tarifasList, ...packHorasList],
      transform: c => [
        c.alumno, 
        c.tarifa ? c.tarifa : `${c.pack} (${c.horas}h)`, 
        getCurso(c.curso, c.tipologiaAcademica),
        c.objetivo
      ]
    })
    downloadCSVFile(content, `contrataciones-${centro}-${mes}`)
  }


  return (
    <PageLoading isLoading={isLoadingPacks || isLoadingTarifas}>
      <TableHeader
        actions={
          <div className={css.actions}>
            <IconButton className={css.download} size="small" color="primary" onClick={handleExport}>
              <i className="material-icons">download</i>
            </IconButton>
            <AcademiaSelector
              name="academia" 
              label="Academia" 
              value={centro} 
              onChange={e => setCentro(e.target.value)} 
            />
            <MesRecienteSelector 
              className={css.mes} 
              name="mes" label="Mes" 
              value={mes} onChange={e => setMes(e.target.value)} 
            />
          </div>
        }
        title="Contrataciones mensuales"
        search={search}
        onSearchChange={e => setSearch(e.target.value)}
      />
      <Tabs
        className={css.tabs}
        tabs={[
          { name: "Pack horas", content: <PackHorasMes search={search} packs={packHorasList} /> },
          { name: "Tarifas", content: <TarifasMes search={search} tarifas={tarifasList} /> },
        ]}
      />
      
    </PageLoading>
  )
}


export default Contrataciones;
