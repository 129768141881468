import { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { Alert, Chip, Fab, FormControl, InputLabel, Link, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material"
import AlumnoSelector from "../../../../components/form/AlumnoSelector/AlumnoSelector"
import { useNotification } from "../../../../context/NotificationManager/NotificationManager"
import { dateToString, getDateFromObjectId } from "../../../../utils/date"
import { format, formatForExport } from "../../../../utils/math"
import ModalModificarPago from "../../../../modals/ModalModificarPago/ModalModificarPago"
import { addContratacionModificacion, getContratacionDetails, getFromAlumno } from "../../../../utils/api/contrataciones"
import { getFactura } from "../../../../utils/documents"
import { isBlank } from "../../../../utils/string"
import ModalConfirmacion from "../../../../modals/ModalConfirmacion/ModalConfirmacion"
import css from './Modificar.module.css'
import { deletePago } from "../../../../utils/api/pagos"

const getTipoContratacion = (id, { pagos, tarifas, intensivos, packs })=> {
  if (pagos.find(p=> p.id === id)) return 'pago'
  if (tarifas.find(t=> t.id === id)) return 'pago-tarifa'
  if (intensivos.find(i=> i.id === id)) return 'pago-intensivo'
  if (packs.find(p=> p.id === id)) return 'pago-pack-horas'
  return ''
}

const ContratacionSelector = ({ value, options, onChange })=> (
  <FormControl className={css.contratacion} variant="standard">
    <InputLabel id='contratacion'>Contratacion</InputLabel>
    <Select
      labelId='contratacion'
      id='contratacion'
      value={value}
      onChange={onChange}
      label='Contratación'
    >
      {options.map(({ id, concepto })=> <MenuItem key={id} value={id}>{concepto}</MenuItem> )}
    </Select>
  </FormControl>
)

const TablaResultadoPago = ({ resultado })=> (
  <div className={css.section}>
    <h2 className={css.sectionTitle}>Resultado del pago</h2>
    {resultado.importeAlternativo && (
      <Alert className={css.infoBanner} severity="info">
        Este pago no se ha cobrado conforme a los precios/hora indicados. 
        Se ha cobrado un importe de <strong>{format(resultado.importeAlternativo)} €.</strong>
        <br />
        <strong>Justificación:</strong> {resultado.justificacionImporte}
      </Alert>
    )}
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Clase</TableCell>
          <TableCell>Asignatura</TableCell>
          <TableCell>Tipo</TableCell>
          <TableCell>Precio</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {resultado.resultados.map(({ precio, explicacion, asistencia })=> (
          <TableRow key={asistencia.id}>
            <TableCell>
              <Tooltip title={explicacion}>
                {dateToString(asistencia.fecha)} - {asistencia.hora}
              </Tooltip>
            </TableCell>
            <TableCell>{asistencia.asignatura} ({asistencia.nivel})</TableCell>
            <TableCell>{asistencia.clase}</TableCell>
            <TableCell>{format(precio)} €</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
)

const TablaDetallesPago = ({ contratacion })=> (
  <div className={css.section}>
    <h2 className={css.sectionTitle}>Detalles del pago</h2>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Centro</TableCell>
          <TableCell>Fecha</TableCell>
          <TableCell>Promociones</TableCell>
          <TableCell>Importe</TableCell>
          <TableCell>Modificación</TableCell>
          <TableCell>Total</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{contratacion.centro}</TableCell>
          <TableCell>{dateToString(contratacion.fecha)}</TableCell>
          <TableCell>
            {contratacion.codigosPromocionales.length === 0 && (
              <p className={css.placeholder}>Sin promoción</p>
            )}
            {contratacion.codigosPromocionales.map(promocion=> (
              <Chip key={promocion} label={promocion} color='primary' />
            ))}
          </TableCell>
          <TableCell>{format(contratacion.importePago - contratacion.modificacion)} €</TableCell>
          <TableCell>{format(contratacion.modificacion)} €</TableCell>
          <TableCell>{format(contratacion.importePago)} €</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </div>
)

const TablaIngresos = ({ ingresos=[] })=> (
  <div className={css.section}>
    <h2 className={css.sectionTitle}>Ingresos realizados</h2>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Concepto</TableCell>
          <TableCell>Centro</TableCell>
          <TableCell>Fecha</TableCell>
          <TableCell>Importe</TableCell>
          <TableCell>Factura</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {ingresos.map(ingreso=> (
          <TableRow key={ingreso.id}>
            <TableCell>{ingreso.concepto}</TableCell>
            <TableCell>{ingreso.centro}</TableCell>
            <TableCell>{dateToString(ingreso.fecha)}</TableCell>
            <TableCell>{format(ingreso.importePago)} €</TableCell>
            <TableCell>
              {ingreso.codigoFactura ? (
                <a 
                  className={css.link} 
                  href={getFactura({
                    academia: ingreso.centro, 
                    numero_factura: ingreso.codigoFactura,
                    cliente: ingreso.nombreFactura,
                    nif: ingreso.identificacionFactura,
                    referencia: ingreso.id,
                    fecha_emision: ingreso.fechaFactura,
                    concepto: ingreso.conceptoFactura,
                    importe: formatForExport(ingreso.importe),
                  })} 
                  target='_blank' 
                  rel='noopener noreferrer'
                >
                  {ingreso.codigoFactura}
                </a>
              ) : '--'}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
)

const TablaModificaciones = ({ modificaciones=[] })=> (
  <div className={css.section}>
    <h2 className={css.sectionTitle}>Modificaciones Realizadas</h2>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Modificaciones</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {modificaciones.map((modificacion, i)=> (
          <TableRow key={i}>
            <TableCell>{modificacion}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
)

const Modificar = ()=> {

  const notification = useNotification()
  const queryClient = useQueryClient()

  const [alumno, setAlumno] = useState({})
  const [contratacion, setContratacion] = useState('')

  const [isModificarModalOpen, setModificarModalOpen] = useState(false)
  const [isBorrarModalOpen, setBorrarModalOpen] = useState(false)

  useEffect(()=> {
    setContratacion('')
  }, [alumno])

  const { isLoading: isLoadingPacks, data: packList=[] } = useQuery({
    queryKey: ['pago-pack-horas', 'list', alumno.id], 
    enabled: alumno && alumno.id !== null,
    queryFn: ()=> getFromAlumno('pago-pack-horas', { alumno: alumno.id })
      .then(datos=> datos.map(pack=> ({
        ...pack,
        concepto: `${pack.concepto} (${dateToString(getDateFromObjectId(pack.id))})`,
      })))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los packs', content: err })
        return []
      })
  })

  const { isLoading: isLoadingTarifas, data: tarifaList=[] } = useQuery({ 
    queryKey: ['pago-tarifa', 'list', alumno.id], 
    enabled: alumno && alumno.id !== null,
    queryFn: ()=> getFromAlumno('pago-tarifa', { alumno: alumno.id })
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las tarifas', content: err })
        return []
      })
  })

  const { isLoading: isLoadingIntensivos, data: intensivoList=[] } = useQuery({ 
    queryKey: ['pago-intensivo', 'list', alumno.id], 
    enabled: alumno && alumno.id !== null,
    queryFn: ()=> getFromAlumno('pago-intensivo', { alumno: alumno.id })
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los intensivos', content: err })
        return []
      })
  })

  const { isLoading: isLoadingPagos, data:pagoList=[] } = useQuery({ 
    queryKey: ['pago', 'list', alumno.id], 
    enabled: alumno && alumno.id !== null,
    queryFn: ()=> getFromAlumno('pago', { alumno: alumno.id })
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los pagos', content: err })
        return []
      })
  })

  const { data: contratacionDetalles={} } = useQuery({
    queryKey: ['contratacion', 'details', contratacion], 
    enabled: !isBlank(contratacion),
    queryFn: ()=> {
      const tipo = getTipoContratacion(contratacion, { 
        pagos: pagoList,
        packs: packList,
        tarifas: tarifaList,
        intensivos: intensivoList
      })
      return getContratacionDetails(tipo, { id: contratacion })
        .then(datos=> datos)
        .catch(err=> {
          notification.error({ title: 'Error al recuperar los detalles', content: err })
          return {}
        })
    }
  })

  const { isFetching: isUpdating, mutate: addModificacion} = useMutation({
    mutationFn: addContratacionModificacion,
    onSuccess: ()=> {
      notification.success({ title: 'Modificación realizada', content: 'Se ha añadido la modificación correctamente' })
      queryClient.invalidateQueries(['contratacion', 'details', contratacion])
    },
    onError: err=> notification.error({ title: 'Error al modificar el pago', content: err }),
  })

  const { isFetching: isDeleting, mutate: borrarPago} = useMutation({
    mutationFn: deletePago,
    onSuccess: ()=> {
      notification.success({ title: 'Pago borrado', content: 'Se ha borrado el pago correctamente' })
      queryClient.invalidateQueries(['pago', 'list', alumno.id])
      setContratacion('')
    },
    onError: err=> notification.error({ title: 'Error al borrar el pago', content: err }),
  })

  const handleModificarPago = ({ justificacion, importe })=> {
    if (isUpdating) return
    const tipo = getTipoContratacion(contratacionDetalles.id, { 
      pagos: pagoList,
      packs: packList,
      tarifas: tarifaList,
      intensivos: intensivoList
    })
    addModificacion({
      contratacion: tipo,
      id: contratacionDetalles.id,
      justificacion,
      importe
    })
    setModificarModalOpen(false)
  }

  const handleBorrarPago = ()=> {
    if (isDeleting) return
    borrarPago({ id: contratacionDetalles.id })
    setBorrarModalOpen(false)
  }

  const isContratacionLoading = isLoadingPacks || isLoadingTarifas || isLoadingIntensivos || isLoadingPagos
  const opcionesContratacion = [
    ...packList,
    ...tarifaList,
    ...intensivoList,
    ...pagoList
  ].sort((a, b)=> {
    if (a.id > b.id) return -1
    else if (a.id < b.id) return 1
    else return 0
  })

  return (
    <div className={css.main}>
      <h1 className={css.title}>Modificar pagos de alumnos</h1>
      <div className={css.header}>
        <AlumnoSelector
          className={css.alumnos}
          name='alumno'
          label='Alumno'
          value={alumno}
          onChange={(_e, value)=> setAlumno(value)}
        />
        <ContratacionSelector
          value={contratacion || ''}
          onChange={e=> setContratacion(e.target.value)}
          options={isContratacionLoading ? [] : opcionesContratacion}
        />
      </div>
      {contratacionDetalles.id && (
        <>
          {contratacionDetalles.tipo === 'Pago' && (
            <Alert className={css.removeBanner} severity="error">
              Este pago puede ser borrado.
              <Link
                className={css.removeLink}
                component="button"
                color="error"
                variant="body2"
                onClick={()=> setBorrarModalOpen(true)}
              >
                Borrar pago
              </Link>
            </Alert>
          )}
          <div className={css.details}>
            <TablaDetallesPago contratacion={contratacionDetalles} />
            {contratacionDetalles.ingresos.length > 0 && (
              <TablaIngresos ingresos={contratacionDetalles.ingresos} />
            )}
            {contratacionDetalles.modificaciones.length > 0 && (
              <TablaModificaciones modificaciones={contratacionDetalles.modificaciones} />
            )}
            {contratacionDetalles.resultado && (
              <TablaResultadoPago resultado={contratacionDetalles.resultado} />
            )}
          </div> 
          <Fab
            className={css.nuevo}
            onClick={()=> setModificarModalOpen(true)}
            size="medium"
            color="primary" 
            aria-label="add"
          >
            <i className='material-icons'>add</i>
          </Fab>
          <ModalModificarPago
            open={isModificarModalOpen}
            onSubmit={handleModificarPago}
            onClose={()=> setModificarModalOpen(false)}
          />
          <ModalConfirmacion
            open={isBorrarModalOpen}
            title='¿Seguro que quieres borrar el pago'
            content={(
              <>
                <p>Se va a borrar <strong>{contratacionDetalles.concepto}</strong></p>
                <p>
                  Solo se pueden borrar pagos en el mismo mes que se han realizado. 
                  Además, el pago no puede formar parte de una matrícula, ni tener ingresos asignados.
                </p>
              </>
            )}
            onClose={()=> setBorrarModalOpen(false)}
            onClick={handleBorrarPago}
          />
        </>
      )}
    </div>
  )

}

export default Modificar