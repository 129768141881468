import { useState } from 'react'
import { IconButton } from '@mui/material'
import InformationTable from '../../../components/Table/InformationTable'
import { useQuery } from '@tanstack/react-query'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import { downloadCSVFile, getCSVContent } from '../../../utils/csv'
import TableHeader from '../../../components/TableHeader/TableHeader'
import PageLoading from '../../../components/PageLoading/PageLoading'
import { textFilter } from '../../../utils/table'
import FechaSelector from '../../../components/form/FechaSelector/FechaSelector'
import { dateToDateTime, dateToString, dateToTimeString, getToday } from '../../../utils/date'
import UsuarioSelector from '../../../components/form/UsuarioSelector/UsuarioSelector'
import { getLogs } from '../../../utils/api/registrosAcceso'
import css from './Logs.module.css'

const Logs = ()=> {

  const notification = useNotification()

  const [search, setSearch] = useState('')
  const [usuario, setUsuario] = useState('')
  const [fecha, setFecha] = useState(dateToDateTime(getToday()))

  const { isLoading, data: logList=[] } = useQuery({
    queryKey: ['log', 'registro-acceso', dateToString(fecha), usuario],
    enabled: !!usuario && !!fecha,
    queryFn: ()=> getLogs({ usuario: usuario || null, fecha: dateToString(fecha) })
    .then(datos=> datos.map(row=> ({
      ...row,
      fecha: `${dateToString(row.fecha)} ${dateToTimeString(row.fecha)}`
    })))
    .catch(err=> {
      notification.error({ title: 'Error al recuperar los logs', content: err })
      return []
    })
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Usuario', 'E-mail', 'Fecha', 'Método', 'URL'],
      data: logList,
      transform: c=> ([
        c.usuario,
        c.email,
        c.fecha,
        c.metodo,
        c.url
      ])
    })
    downloadCSVFile(content, `logs`)
  }
  
  const filterSearch = log=> {
    return textFilter({ 
      object: log, 
      fields: ['metodo', 'url', 'nombre', 'email'],
      search: search
    })
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <IconButton 
              className={css.download} 
              disabled={!logList.length}
              size='small' 
              color='primary' 
              onClick={handleExport}
            >
              <i className='material-icons'>download</i>
            </IconButton>
            <FechaSelector 
              className={css.fecha}
              label='Fecha' 
              value={fecha} 
              onChange={setFecha} 
            />
            <UsuarioSelector
              className={css.academia}
              name='usuario'
              label='Usuario'
              value={usuario}
              onChange={e=> setUsuario(e.target.value)}
            />
          </div>
        )}
        title={`${logList.length} logs`}
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        details={[
          { title: 'Nombre', key: 'nombre' },
          { title: 'E-mail', key: 'email' },
          { title: 'Fecha', key: 'fecha' },
          { title: 'Método', key: 'metodo' },
          { title: 'URL', key: 'url' }
        ]}
        data={logList.filter(filterSearch).map((log) => ({
          nombre: log.usuario,
          email: log.email,
          fecha: log.fecha,
          metodo: log.metodo,
          url: log.url
        }))}
        isFetching={isLoading}
      />
    </PageLoading>
  )
}

export default Logs
