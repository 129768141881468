import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from '@mui/material'
import css from './SmallTable.module.css'


const SmallTable = ({ details = [], data, size, hover=true }) => {

  return (
    <TableContainer>
      <Table stickyHeader className={css.table} size={size || 'medium'}>
        <TableHead>
          <TableRow>
            {details.map((detail) => (
              <TableCell key={detail.title} className={css.cell} align={detail.align}>
                {detail.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow hover={hover} key={index}>
              {details.map(detail => (
                <TableCell key={detail.key} className={detail.className || ''} align={detail.align}>
                  {row[detail.key]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SmallTable