import { IconButton } from '@mui/material'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import EjercicioSelector from '../../../../components/form/EjercicioSelector/EjercicioSelector'
import { getAuth } from '../../../../utils/auth'
import { MONTHS } from '../../../../utils/date'
import { format, formatForExport } from '../../../../utils/math'
import { downloadCSVFile, getCSVContent } from '../../../../utils/csv'
import AcademiaSelector from '../../../../components/form/AcademiaSelector/AcademiaSelector'
import PageLoading from '../../../../components/PageLoading/PageLoading'
import InformationTable from '../../../../components/Table/InformationTable'
import TableHeader from '../../../../components/TableHeader/TableHeader'
import { useNotification } from '../../../../context/NotificationManager/NotificationManager'
import { getLeadList } from '../../../../utils/api/leads'
import { getLeadDetails } from '../../../../utils/api/peticionesWeb'
import css from './CaptacionCentro.module.css'

const STARTING_VALUE = { leads: 0, pruebas: 0, captados: 0, abiertos: 0, novalidos: 0 }

const processDatos = ({ leads, peticiones, ejercicio })=> {
  const meses = []
  // Fill with initial values
  MONTHS.slice(8).forEach(m=> {
    meses.push({ name: `${m} ${ejercicio}`, value: {...STARTING_VALUE} })
    meses.push({ name: `${m} ${ejercicio} (Web)`, value: {...STARTING_VALUE} })
    meses.push({ name: `${m} ${ejercicio} (SEM)`, value: {...STARTING_VALUE} })
  })
  MONTHS.slice(0, 8).forEach(m=> {
    meses.push({ name: `${m} ${ejercicio+1}`, value: {...STARTING_VALUE} })
    meses.push({ name: `${m} ${ejercicio+1} (Web)`, value: {...STARTING_VALUE} })
    meses.push({ name: `${m} ${ejercicio+1} (SEM)`, value: {...STARTING_VALUE} })
  })

  // Process the leads
  leads
    .sort((l1, l2)=> l1.primerContacto - l2.primerContacto)
    .map(lead=> ({
      ...lead,
      fecha: new Date(lead.primerContacto),
      mes: `${MONTHS[new Date(lead.primerContacto).getMonth()]} ${new Date(lead.primerContacto).getFullYear()}`
    }))
    .forEach(lead=> {
      const mes = meses.find(m=> m.name === lead.mes)
      mes.value.leads++
      if (lead.prueba) mes.value.pruebas++
      if (!lead.estado.startsWith('CLIENTE') && (lead.estado !== 'LEAD_NO_VALIDO')) mes.value.abiertos++
      if (lead.estado === 'CLIENTE_CAPTADO') mes.value.captados++
      if (lead.estado === 'LEAD_NO_VALIDO') mes.value.novalidos++
    })
  // Process the web requests
  peticiones
    .sort((l1, l2)=> l1.primerContacto - l2.primerContacto)
    .map(peticion=> ({
      ...peticion,
      fecha: new Date(peticion.primerContacto),
      mes: `${MONTHS[new Date(peticion.primerContacto).getMonth()]} ${new Date(peticion.primerContacto).getFullYear()}`
    }))
    .forEach(peticion=> {
      const mes_web = meses.find(m=> m.name === `${peticion.mes} (Web)`)
      const mes_sem = meses.find(m=> m.name === `${peticion.mes} (SEM)`)
      const isSem = peticion.origenSEM
      mes_web.value.leads++
      if (isSem) mes_sem.value.leads++
      if (peticion.prueba) mes_web.value.pruebas++
      if (isSem && peticion.prueba) mes_sem.value.pruebas++
      if (!peticion.estado.startsWith('CLIENTE') && (peticion.estado !== 'LEAD_NO_VALIDO')) mes_web.value.abiertos++
      if (isSem && !peticion.estado.startsWith('CLIENTE') && (peticion.estado !== 'LEAD_NO_VALIDO')) mes_sem.value.abiertos++
      if (peticion.estado === 'CLIENTE_CAPTADO') mes_web.value.captados++
      if (isSem && peticion.estado === 'CLIENTE_CAPTADO') mes_sem.value.captados++
      if (peticion.estado === 'LEAD_NO_VALIDO') mes_web.value.novalidos++
      if (isSem && peticion.estado === 'LEAD_NO_VALIDO') mes_sem.value.novalidos++
    })

  // Generate totals
  const totals_sem = {...STARTING_VALUE}
  const totals_web = {...STARTING_VALUE}
  const total_leads = {...STARTING_VALUE}

  const combine = (totals, mes)=> {
    totals.leads += mes.leads
    totals.pruebas += mes.pruebas
    totals.captados += mes.captados
    totals.abiertos += mes.abiertos
    totals.novalidos += mes.novalidos
  }

  meses.forEach(mes=> {
    if (mes.name.endsWith("(Web)")) combine(totals_web, mes.value)
    else if (mes.name.endsWith("(SEM)")) combine(totals_sem, mes.value)
    else combine(total_leads, mes.value)
  })
  
  return [
    ...meses, 
    { name: 'Total', value: total_leads },
    { name: 'Total (Web)', value: totals_web },
    { name: 'Total (SEM)', value: totals_sem }
  ]
}

const CaptacionCentro = ()=> {

  const notification = useNotification()

  const [centro, setCentro] = useState(getAuth().centro)
  const [ejercicio, setEjercicio] = useState((new Date()).getFullYear())

  const { isLoading: isLoadingLeads, data: feedbackList=[] } = useQuery({
    queryKey: ['feedback', 'list', ejercicio, centro], 
    queryFn: ()=> getLeadList({
      fecha_inicio: `09/${ejercicio}`,
      fecha_fin: `09/${ejercicio+1}`,
      "centros[]": [centro]
    })
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los leads', content: err })
        return []
      })
  })

  const { isLoading: isLoadingPeticiones, data: peticionList=[] } = useQuery({
    queryKey: ['peticion_web', 'list', 'feedback_details', ejercicio, centro], 
    queryFn: ()=> getLeadDetails({
      fecha_inicio: `09/${ejercicio}`,
      fecha_fin: `09/${ejercicio+1}`,
      "centros[]": [centro]
    })
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las peticiones web', content: err })
        return []
      })
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Mes', 'Leads', 'No válidos', 'Pruebas', 'Abiertos', 'Captados', 'Lead → Prueba', 'Prueba → Alumno', 'Lead → Alumno' ],
      data: processDatos({ leads: feedbackList, peticiones: peticionList, ejercicio }),
      transform: m=> ([
        m.name,
        `${m.value.leads}`,
        `${m.value.novalidos}`,
        `${m.value.pruebas}`,
        `${m.value.captados}`,
        `${m.value.abiertos}`,
        `${formatForExport(100 * m.value.pruebas / (m.value.leads - m.value.novalidos))}%`,
        `${formatForExport(100 * m.value.captados / m.value.pruebas)}%`,
        `${formatForExport(100 * m.value.captados / (m.value.leads - m.value.novalidos))}%`
      ])
    })
    downloadCSVFile(content, 'captacion-centro')
  }

  return (
    <PageLoading isLoading={isLoadingLeads || isLoadingPeticiones}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <AcademiaSelector
              name='academia'
              label='Academia'
              value={centro}
              onChange={e=> setCentro(e.target.value)}
            />
            <EjercicioSelector
              className={css.year}
              name='ejercicio'
              label='Ejercicio'
              value={ejercicio}
              onChange={e=> setEjercicio(e.target.value)}
            />
            <IconButton className={css.download} size='small' color='primary' onClick={handleExport}>
              <i className='material-icons'>download</i>
            </IconButton>
          </div>
        )}
        title='Resultados'
        showSearch={false}
      />
      <InformationTable
        details={[
          { title: 'Mes', key: 'mes', align: 'left' },
          { title: 'Leads', key: 'leads', sortDisabled: true, align: 'center' },
          { title: 'No válidos', key: 'no_validos', sortDisabled: true, align: 'center' },
          { title: 'Pruebas', key: 'pruebas', sortDisabled: true, align: 'center' },
          { title: 'Abiertos', key: 'abiertos', sortDisabled: true, align: 'center' },
          { title: 'Captados', key: 'captados', sortDisabled: true, align: 'center' },
          { title: 'Lead → Prueba', key: 'conversion_lead_prueba', sortDisabled: true, align: 'center' },
          { title: 'Prueba → Alumno', key: 'conversion_prueba_alumno', sortDisabled: true, align: 'center' },
          { title: 'Lead → Alumno', key: 'conversion_lead_alumno', sortDisabled: true, align: 'center' },
        ]}
        data={processDatos({ leads: feedbackList, peticiones: peticionList, ejercicio })
          .map(mes=> ({
            mes: mes.name.startsWith("Total") ? <strong>{mes.name}</strong> : mes.name,
            leads: mes.value.leads,
            no_validos: <span className={css.error}>{mes.value.novalidos}</span>,
            pruebas: mes.value.pruebas,
            captados: <span className={css.success}>{mes.value.captados}</span>,
            abiertos: mes.value.abiertos,
            conversion_lead_prueba: `${format(100 * mes.value.pruebas / (mes.value.leads - mes.value.novalidos))}%`,
            conversion_prueba_alumno: `${format(100 * mes.value.captados / mes.value.pruebas)}%`,
            conversion_lead_alumno: `${format(100 * mes.value.captados / (mes.value.leads - mes.value.novalidos))}%`
          }))
        }
      />
    </PageLoading>
  )

}

export default CaptacionCentro