import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { IconButton } from '@mui/material'
import { format, formatForExport } from '../../../../utils/math'
import { downloadCSVFile, getCSVContent } from '../../../../utils/csv'
import PageLoading from '../../../../components/PageLoading/PageLoading'
import InformationTable from '../../../../components/Table/InformationTable'
import TableHeader from '../../../../components/TableHeader/TableHeader'
import { useNotification } from '../../../../context/NotificationManager/NotificationManager'
import MesRecienteSelector from '../../../../components/form/MesRecienteSelector/MesRecienteSelector'
import AcademiaSelector from '../../../../components/form/AcademiaSelector/AcademiaSelector'
import { getAuth } from '../../../../utils/auth'
import { monthToString } from '../../../../utils/date'
import { textFilter } from '../../../../utils/table'
import { getPendientes } from '../../../../utils/api/pagos'
import css from './Pendientes.module.css'

const Pendientes = ()=> {

  const notification = useNotification()

  const [mes, setMes] = useState(monthToString(new Date()))
  const [centro, setCentro] = useState(getAuth().centro)
  const [search, setSearch] = useState('')

  const { isLoading: isLoadingPagos, data: pagoList=[] } = useQuery({
    queryKey: ['pago', 'pending', 'list', mes, centro], 
    queryFn: ()=> getPendientes({ mes, centro })
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los pagos pendientes', content: err })
        return []
      })
  })


  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Alumno', 'Concepto', 'Centro', 'Tipo', 'Importe', 'Deuda'],
      data: pagoList,
      transform: pago=> ([
        pago.alumno,
        pago.concepto,
        pago.centro,
        pago.tipo,
        formatForExport(pago.importe),
        formatForExport(pago.deuda)
      ])
    })
    downloadCSVFile(content, 'pagos-pendientes-mes')
  }

  const filterSearch = pago=> {
    return textFilter({ 
      object: pago, 
      fields: ['alumno', 'concepto', 'tipo'],
      search: search
    })
  }

  return (
    <PageLoading isLoading={isLoadingPagos}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <AcademiaSelector
              name='academia'
              label='Academia'
              value={centro}
              onChange={e=> setCentro(e.target.value)}
            />
            <MesRecienteSelector
              className={css.mes}
              name='mes'
              label='Mes'
              value={mes}
              onChange={e=> setMes(e.target.value)}
            />
            <IconButton className={css.download} size='small' color='primary' onClick={handleExport}>
              <i className='material-icons'>download</i>
            </IconButton>
          </div>
        )}
        title='Pendientes'
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        details={[
          { title: 'Alumno', key: 'alumno', align: 'left' },
          { title: 'Concepto', key: 'concepto', align: 'left' },
          { title: 'Tipo', key: 'tipo', align: 'left' },
          { title: 'Importe', key: 'importe', sortDisabled: true, align: 'center' },
          { title: 'Deuda', key: 'deuda', sortDisabled: true, align: 'center' },
        ]}
        data={pagoList
          .filter(filterSearch)
          .map(pago=> ({
            alumno: pago.alumno,
            concepto: pago.concepto,
            tipo: pago.tipo,
            importe: `${format(pago.importe)} €`,
            deuda: `${format(pago.deuda)} €`,
          }))
        }
      />
    </PageLoading>
  )

}

export default Pendientes