import { GET, DELETE, PUT } from '../request'

export const getFromAlumno = ({ alumno })=> GET('/api/core/alumnos/contabilidad/ingreso-alumno/list/alumno', { alumno })

export const deleteIngreso = ({ id })=> DELETE('/api/core/alumnos/contabilidad/ingreso-alumno', { id })

export const unassignIngreso = ({ id })=> PUT('/api/core/alumnos/contabilidad/ingreso-alumno/unassign', { id })

export const updateIngreso = ({ id, importe, concepto, forma_pago, observaciones, centro })=> (
  PUT('/api/core/alumnos/contabilidad/ingreso-alumno', {
    id,
    importe,
    concepto,
    forma_pago,
    observaciones,
    centro
  })
)